import moment from "moment";
import React from "react";

function ChatItem({ message }) {
  return (
    <div className="mb-4">
      <div className="message">
        <p className="text">{message.message}</p>
      </div>
      <div className="d-flex justify-content-end mt-1">
        <p className="message-date-time">
          {moment.utc(message.sent_at).local().format("MM-DD-YYYY hh:mm A")}
        </p>
      </div>
    </div>
  );
}

export default ChatItem;
