import {
  Alert,
  Box,
  Button,
  Divider,
  TextField,
  Typography,
  Grid,
  Stack,
} from "@mui/material";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import { login } from "../../sevices/apiAuth";
// import GraphicElements from "../../styles/svg/graphic_elements.svg";
import Logo from "../../../styles/svg/Logo.svg";
import { useDispatch } from "react-redux";
import { loginUser } from "../../../sevices/authApi";
// import { user } from "../../redux/usersSlice";
import image from "../../../styles/img/auth-image.png";
import "../Auth.scss";
import useCustomForm from "../../../hooks/useForm";
import { deFormatPhoneNumber, formatPhoneNumber } from "../../../utils/helpers";
import { createClient } from "../../../sevices/clientApi";
import toast from "react-hot-toast";

function SignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [apiError, setApiError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    errors,
    onSubmit,
    handleReset,
    setValue,
    watch,
    reset,
    setError,
    clearErrors,
    isValid,
  } = useCustomForm();

  const password = watch("password");

  const fields = [
    {
      name: "name",
      label: "Full Name",
      type: "text",

      placeholder: "Enter name",
    },
    {
      name: "position",
      label: "Position",
      type: "text",

      placeholder: "Enter position",
    },
    {
      name: "phone_number",
      label: "Phone #",
      type: "number",

      placeholder: "Enter phone number",
    },
    {
      name: "email",
      label: "Email Address",
      type: "text",

      placeholder: "Enter Email",
    },
    {
      name: "password",
      label: "Login Password",
      type: "password",

      placeholder: "Enter Password",
    },
    {
      name: "confirm_password",
      label: "Confirm Password",
      type: "password",

      placeholder: "Enter Password",
    },
  ];

  const mailingFields = [
    {
      name: "street_address",
      label: "Street Address",
      type: "text",
      grid: 12,
      placeholder: "Enter Street Address",
    },
    {
      name: "address",
      label: "Address Line 2",
      type: "text",
      grid: 12,
      placeholder: "Enter Address Line 2",
    },
    {
      name: "city",
      label: "City",
      type: "text",
      grid: 12,
      placeholder: "Enter City",
    },
    {
      name: "state",
      label: "State / Region / Province",
      type: "text",
      grid: 7,
      placeholder: "Enter State",
    },
    {
      name: "zip_code",
      label: "Zip Code",
      type: "text",
      grid: 5,
      placeholder: "Enter Zip Code",
    },
  ];

  const portfolioFields = [
    {
      name: "building_address",
      label: "List 1 property address in your portfolio.",
      type: "text",

      placeholder: "Enter Propertyt Address",
    },
    {
      name: "borough",
      label: "Borough",
      type: "text",

      placeholder: "Enter Borough",
    },
    // {
    //   name: "superintendent_name",
    //   label: "Superintendent Name",
    //   type: "text",

    //   placeholder: "Enter Superintendent Name",
    // },
    // {
    //   name: "superintendent_number",
    //   label: "Superintendent Phone Number",
    //   type: "number",

    //   placeholder: "Enter Superintendent Phone #",
    // },
  ];

  //Current Url
  const url = localStorage.getItem("redirectLocation");

  /**
   * Set details to local storage
   */
  const setDetails = (data) => {
    // dispatch(user(data.user_data));
    localStorage.setItem("token", data.access_token);
    // localStorage.setItem("role", data.user_type);
    localStorage.setItem("userData", JSON.stringify(data.user_data));
    // localStorage.setItem({
    //   key: "userId",
    //   value: data.user_data.user_id,
    // });
  };

  /**
   * Create form data
   */
  // function createFormData(data) {
  //   const formData = new FormData();
  //   formData.append("email", data.email);
  //   formData.append("password", data.password);
  //   return formData;
  // }

  const submit = (data) => {
    /**
     * Registration Client
     */
    setApiError("");
    setLoading(true);
    const client = {
      email: data.email,
      password: data.password,
      name: data.name,
      position: data.position,
      phone_number: deFormatPhoneNumber(data.phone_number),
      company_name: data.company_name,
      street_address: data.street_address,
      address: data.address,
      city: data.city,
      zip_code: data.zip_code,
      building: {
        address: data.building_address,
        borough: data.borough,
        superintendent_name: data.superintendent_name,
        superintendent_number: data.superintendent_number,
      },
    };

    createClient(client)
      .then((res) => {
        let data = res.data.data;
        reset();
        toast.success(res.data.message);
      })
      .catch((err) => {
        console.log(err);
        if (err.code == "ERR_NETWORK") {
          setApiError("An unexpected error occurred. Please try again later");
        } else setApiError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function onError(errors) {
    console.log(errors);
  }

  return (
    <Box
      component="main"
      // sx={{ backgroundImage: `url(${BgSvg})` }}
      sx={{
        height: "100vh",
        display: "flex",
        // justifyContent: "center",
        alignItems: "center",
        // backgroundImage: `url(${GraphicElements})`,
        // backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        // backgroundPosition: "center",
      }}
      disableGutters={true}
    >
      <Box
        sx={{
          // display: "flex",
          // flexDirection: "column",
          // alignItems: "center",
          maxWidth: "400px",
          // justifyContent: "center",
          overflow: "auto",
          height: "100vh",
          padding: "0px 40px",
        }}
      >
        <img src={Logo} alt="not found" style={{ marginTop: "47px" }} />
        <Box
          component="form"
          onSubmit={handleSubmit(submit, onError)}
          noValidate
          sx={{
            marginTop: "60px",
            // padding: "0px 40px",
            borderRadius: "16px",
            // boxShadow: "0px 4px 30px 0px rgba(27, 62, 97, 0.06)",
            background: "#ffffff",
          }}
        >
          <Typography className="page-header-text" component="h1" variant="h5">
            Client Intake Form
          </Typography>
          <Typography className="info-text">
            Thank you for choosing CompliNYC for your inspection & testing
            needs, to establish a client account. please complete the form
            below.
          </Typography>
          {apiError && <Alert severity="error">{apiError}</Alert>}
          <Stack>
            <Grid container spacing={4}>
              <Grid item xs={12} md={12}>
                <TextField
                  label={"Entity / Management Company"}
                  fullWidth
                  {...register("company_name", {
                    required: `${"Entity / Management Company"} is required`,
                  })}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors["company_name"]}
                  helperText={errors["company_name"]?.message}
                  // disabled={field.disabled || false}
                  placeholder={"Enter name"}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#16304f", // Change this to your desired focus color
                      },
                    },
                    "& .MuiInputLabel-root": {
                      "&.Mui-focused": {
                        color: "#16304f", // Change this to your desired label focus color
                        fontFamily: "segoe_uisemibold",
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
            <div className="p-24-0">
              <p className="schedule-appointment-heading">
                Contact Person<span className="required-star">*</span>
              </p>
            </div>
            <Grid container spacing={4}>
              {fields.map((field, index) => (
                <Grid item xs={12} md={12} key={index}>
                  {field.type === "password" ? (
                    <TextField
                      label={field.label}
                      type="password"
                      autoComplete="new-password"
                      fullWidth
                      {...register(field.name, {
                        required: `${field.label} is required`,
                        ...(field.name == "confirm_password"
                          ? {
                              validate: (value) =>
                                value === password || "Passwords do not match",
                            }
                          : {}),
                      })}
                      InputLabelProps={{ shrink: true }}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message}
                      disabled={field.disabled || false}
                      placeholder={field.placeholder}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#16304f", // Change this to your desired focus color
                          },
                        },
                        "& .MuiInputLabel-root": {
                          "&.Mui-focused": {
                            color: "#16304f", // Change this to your desired label focus color
                            fontFamily: "segoe_uisemibold",
                            fontSize: "13px",
                          },
                        },
                      }}
                    />
                  ) : field.type === "number" ? (
                    <TextField
                      label={field.label}
                      fullWidth
                      {...register(field.name, {
                        required: `${field.label} is required`,
                      })}
                      onChange={(e) => {
                        setValue(
                          "phone_number",
                          formatPhoneNumber(e.target.value)
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message}
                      disabled={field.disabled || false}
                      placeholder={field.placeholder}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#16304f", // Change this to your desired focus color
                          },
                        },
                        "& .MuiInputLabel-root": {
                          "&.Mui-focused": {
                            color: "#16304f", // Change this to your desired label focus color
                            fontFamily: "segoe_uisemibold",
                            fontSize: "13px",
                          },
                        },
                      }}
                    />
                  ) : (
                    <TextField
                      label={field.label}
                      fullWidth
                      {...register(field.name, {
                        required: `${field.label} is required`,
                        ...(field.name == "confirm_password"
                          ? {
                              validate: (value) =>
                                value === password || "Passwords do not match",
                            }
                          : {}),
                      })}
                      InputLabelProps={{ shrink: true }}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message}
                      disabled={field.disabled || false}
                      placeholder={field.placeholder}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#16304f", // Change this to your desired focus color
                          },
                        },
                        "& .MuiInputLabel-root": {
                          "&.Mui-focused": {
                            color: "#16304f", // Change this to your desired label focus color
                            fontFamily: "segoe_uisemibold",
                            fontSize: "13px",
                          },
                        },
                      }}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
            <div className="p-24-0">
              <p className="schedule-appointment-heading">
                Mailing Address<span className="required-star">*</span>
              </p>
            </div>
            <Grid container spacing={4}>
              {mailingFields.map((field, index) => (
                <Grid item xs={field.grid} md={field.grid} key={index}>
                  {
                    <TextField
                      label={field.label}
                      fullWidth
                      {...register(field.name, {
                        required: `${field.label} is required`,
                      })}
                      InputLabelProps={{ shrink: true }}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message}
                      disabled={field.disabled || false}
                      placeholder={field.placeholder}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#16304f", // Change this to your desired focus color
                          },
                        },
                        "& .MuiInputLabel-root": {
                          "&.Mui-focused": {
                            color: "#16304f", // Change this to your desired label focus color
                            fontFamily: "segoe_uisemibold",
                            fontSize: "13px",
                          },
                        },
                      }}
                    />
                  }
                </Grid>
              ))}
            </Grid>
            {/* <div className="p-24-0">
              <p className="schedule-appointment-heading">
                Portfolio<span className="required-star">*</span>
              </p>
            </div>
            <Grid container spacing={4}>
              {portfolioFields.map((field, index) => (
                <Grid item xs={12} md={12} key={index}>
                  {field.type === "number" ? (
                    <TextField
                      label={field.label}
                      fullWidth
                      {...register(field.name, {
                        required: `${field.label} is required`,
                      })}
                      onChange={(e) => {
                        setValue(
                          "superintendent_number",
                          formatPhoneNumber(e.target.value)
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message}
                      disabled={field.disabled || false}
                      placeholder={field.placeholder}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#16304f", // Change this to your desired focus color
                          },
                        },
                        "& .MuiInputLabel-root": {
                          "&.Mui-focused": {
                            color: "#16304f", // Change this to your desired label focus color
                            fontFamily: "segoe_uisemibold",
                            fontSize: "13px",
                          },
                        },
                      }}
                    />
                  ) : (
                    <TextField
                      label={field.label}
                      fullWidth
                      {...register(field.name, {
                        required: `${field.label} is required`,
                      })}
                      InputLabelProps={{ shrink: true }}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message}
                      disabled={field.disabled || false}
                      placeholder={field.placeholder}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#16304f", // Change this to your desired focus color
                          },
                        },
                        "& .MuiInputLabel-root": {
                          "&.Mui-focused": {
                            color: "#16304f", // Change this to your desired label focus color
                            fontFamily: "segoe_uisemibold",
                            fontSize: "13px",
                          },
                        },
                      }}
                    />
                  )}
                </Grid>
              ))}
            </Grid> */}
          </Stack>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className="btn-blue"
            sx={{
              mt: "14px",
              padding: "13px 16px",
              bgcolor: "#ED7047",
              boxShadow: "none",
            }}
            disabled={loading}
          >
            Create Account
          </Button>
          <div
            className="sign-up-bottom-container"
            style={{ marginBottom: "20px" }}
          >
            <p className="sign-up-bottom">
              Already have an account?{" "}
              <span onClick={() => navigate("/")}>Sign In</span>
            </p>
          </div>
        </Box>
      </Box>
      <Box
        sx={{
          flex: 1,
          position: "relative",
          display: { xs: "none", md: "block" },
        }}
        className="right-box"
      >
        <img
          src={image}
          alt="Right side background"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
        <Box
          sx={{
            position: "absolute",
            // top: "50%",
            // left: "50%",
            // transform: "translate(-50%, -50%)",
            // color: "white",
            // textAlign: "center",
          }}
          className="text-overlay"
        >
          <Typography variant="h3" component="div">
            CompliNYC
          </Typography>
          {/* <Typography variant="body1" component="div" mt={2}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Typography> */}
        </Box>
      </Box>
    </Box>
  );
}

export default SignUp;
