import React from "react";
import { Grid } from "@mui/material";

import Service from "./Service";
import { useServices } from "../../../reactQuery/service";
import Loader from "../../../components/Loader";
import DragableList from "./DragableList";

function List() {
  const { isLoading, responseData: services } = useServices();

  return (
    <div className="mt-4">
      <Grid container>
        <DragableList services={services} />
      </Grid>
      {services && services.length > 0 ? (
        ""
      ) : (
        <div className="loader-center">
          {isLoading ? (
            <Loader color={"black"} />
          ) : (
            <p className="orange-color font-family-700">Service Not Found</p>
          )}
        </div>
      )}
    </div>
  );
}

export default List;
