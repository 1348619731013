import React, { useEffect, useState } from "react";
import { useJobs, useTickets } from "../../../../../reactQuery/job";
import { columns } from "./tableColumns";
import CustomTable from "../../../../../components/Table/CustomTable";
import Loader from "../../../../../components/Loader";
import { useParams } from "react-router-dom";

function Table() {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const param = useParams();

  const {
    isLoading,
    error,
    responseData,
    isError,
    refetch,
    totalRecords,
    reportColours,
  } = useTickets({
    page: page + 1,
    pageSize,
    status: "status=Pending",
    search: "",
    date: "",
    pagination: true,
    jobType: "pending",
    client: `&client_id=${param.clientId}`,
  });

  useEffect(() => {
    refetch();
  }, [page, pageSize, param.clientId]);


  return (
    <>
      {isLoading && (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      )}
      {!isLoading && isError && (
        <div className="loader-center">
          <p>{error?.response?.data?.message || "Error"}</p>
        </div>
      )}
      {!isLoading && !isError && (
        <CustomTable
          initialRows={responseData}
          columns={columns}
          // defaultSort={"scheduled_date"}
          headerBgColor={"#F5F5F9"}
          rowCount={totalRecords}
          setPaginationModel={(data) => {
            setPage(data.page);
            setPageSize(data.pageSize);
          }}
          page={page}
          pageSize={pageSize}
          subHeight={360}
        />
      )}
    </>
  );
}

export default Table;
