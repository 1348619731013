import React, { useEffect, useState } from "react";
import { Box, Grid, Stack } from "@mui/material";

import FilterIcon from "../../../styles/svg/filter-icon.svg";
import RadioFilter from "../../../components/Radio/RadioFilter";
import { useMediaQuery } from "react-responsive";
import CustomSelectDropdown from "../../../components/CustomSelectDropDown";
import { useSearchParams } from "react-router-dom";
import SelectDatePicker from "../../../components/SelectDatePicker";
import moment from "moment";

function RightFilters() {
  const isMobile = useMediaQuery({ maxWidth: "1290px" });
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedStatus = searchParams.get("status");
  const date = searchParams.get("date");

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  const options = [
    { value: "Pending", label: "Pending" },
    { value: "Inspected", label: "Inspected" },
    { value: "No Access", label: "No Access" },
    { value: "Not Uploaded", label: "Not Uploaded" },
    { value: "Flexible", label: "Flexible" },
  ];

  const removeSearchParams = (paramsToRemove) => {
    // Create a copy of the current search params
    const newSearchParams = new URLSearchParams(searchParams);

    // Remove each parameter in the paramsToRemove array
    paramsToRemove.forEach((param) => {
      newSearchParams.delete(param);
    });

    // Set the updated search params
    setSearchParams(newSearchParams);
  };

  const handleSelectChange = (value) => {
    updateSearchParams("status", value);
  };
  const removeFilter = () => {
    removeSearchParams(["date", "status"]);
  };

  // useEffect(()=>{
  //   updateSearchParams(
  //     "date",
  //     JSON.stringify({
  //       key: "selection",
  //       startDate: moment(new Date()).format("YYYY-MM-DD"),
  //       endDate: moment(new Date()).format("YYYY-MM-DD"),
  //     })
  //   );
  // },[])

  return (
    <div className="d-flex align-items-center">
      {isMobile ? (
        <>
          <div className="d-flex align-items-center">
            <img
              src={FilterIcon}
              style={{ height: "16px", width: "16px" }}
              className="mr-2"
            />
            <CustomSelectDropdown
              options={options}
              label="Status"
              onSelectChange={handleSelectChange}
              defaultValue={selectedStatus}
            />
          </div>
        </>
      ) : (
        <>
          <div>
            {selectedStatus || date ? (
              <p className="clear-filter" onClick={removeFilter}>
                Clear Filter
              </p>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex align-items-center mr-20">
            <img
              src={FilterIcon}
              style={{ height: "16px", width: "16px" }}
              className="mr-2"
            />
            <p className="filter-text">Filter By:</p>
          </div>
          <div className="d-flex align-items-center">
            <Stack direction="row" spacing={2}>
              <RadioFilter title={"Pending"} value={"Pending"} />
              <RadioFilter title={"Inspected"} value={"Inspected"} />
              <RadioFilter title={"No Access"} value={"No Access"} />
              <RadioFilter title={"Not Uploaded"} value={"Not Uploaded"} />
              <RadioFilter title={"Flexible"} value={"Flexible"} />
            </Stack>
          </div>
        </>
      )}
      <div>
        <SelectDatePicker />
      </div>
    </div>
  );
}

export default RightFilters;
