import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import LongFormDialog from "../../components/Dialog/LongFormDialog";
import EnterSurveyForm from "../LeadCompliance/components/Header/EnterSurveyForm";
import { useCustomSearchParams } from "../../hooks/useCustomSearchParams";
import Logo from "../../styles/svg/raza-logo.svg";
import Horizontal from "../../styles/svg/horizontal.svg";
import BootomShade from "../../styles/svg/bottom-shape.svg";
import "./signature.scss";

function ScanSurvey() {
  const [survey, setSurvey] = useCustomSearchParams("survey");
  const [token, setToken] = useCustomSearchParams("token");

  return (
    <div className="scan-container">
      <DialogTitle
        sx={{ m: 0, padding: "16px", bgcolor: "#f5f5f9", lineHeight: "1" }}
        id="responsive-dialog-title"
        className=""
      >
        <div className=" d-flex">
          <img
            src={Logo}
            alt="Sofmen"
            className="cursor-pointer"
            // onClick={() => redirect("/")}
          />
          <div className="lead-paint-comp-header ml-4">
            <p>Annual Notice Lead Paint & Window Falls</p>
          </div>
          {/* <div className="verticle-div">
            <img src={Horizontal} />
          </div> */}
        </div>
      </DialogTitle>
      <div className="lead-compliance-scan-form">
        {/* <LongFormDialog
        open={true}
        handleClose={() => {}}
        title={"Survey Response"}
        btnTitle={"Confirm"}
      > */}

        <EnterSurveyForm
          isFromQr={true}
          initialData={{ survey_id: survey, dates: new Date() }}
          token={token}
        />
        {/* </LongFormDialog> */}
      </div>
    </div>
  );
}

export default ScanSurvey;
