import React, { useEffect } from "react";
import Header from "./components/Header/Header";
import "./leadComplianse.scss";
import Table from "./components/Table";
import Filters from "./components/Filters";
import ComplinceIcon from "../../styles/svg/compliance-lead.svg";
import { useCustomSearchParams } from "../../hooks/useCustomSearchParams";
import { useLeadCompliance } from "../../reactQuery/leadCompliance";

function LeadCompliance() {
  const [year, setYear] = useCustomSearchParams("year");
  const currentYear = new Date().getFullYear();

  const {
    isLoading,
    error,
    responseData,
    isError,
    refetch,
    totalRecords,
    statusCount,
    isRefetching,
  } = useLeadCompliance(`?page=1&page_size=100${year ? `&year=${year}` : ""}`);
 useEffect(() => {
   setYear("year", year || currentYear);
 }, []);
  return (
    <div>
      <Header />
      {currentYear < year && totalRecords == 0 ? (
        <div className="new-year-blank-compliance">
          <img src={ComplinceIcon} />
        </div>
      ) : (
        <div className="mt-4 ticket-page">
          <Filters />
          <Table />
        </div>
      )}
    </div>
  );
}

export default LeadCompliance;
