import { useQuery } from "@tanstack/react-query";
import {
  getLeadCompliance,
  getMailersCompliance,
} from "../sevices/leadComplianceApi";

export function useLeadCompliance(keys) {
  const { isLoading, data, error, isError, refetch, isRefetching } = useQuery({
    queryKey: ["lead-compliance"],
    queryFn: () => getLeadCompliance(keys),
  });

  const responseData =
    data?.data?.data?.response_data?.map((el, idx) => {
      return {
        ...el,
        id: idx + 1,
      };
    }) || [];
  const totalRecords = data?.data?.data?.total_records;

  const statusCount = data?.data?.data?.status_counts;

  return {
    isLoading,
    error,
    responseData,
    isError,
    refetch,
    totalRecords,
    statusCount,
    isRefetching,
  };
}

export function useMailersCompliance(keys) {
  const { isLoading, data, error, isError, refetch } = useQuery({
    queryKey: ["mailers-compliance", keys],
    queryFn: () => getMailersCompliance(keys),
  });

  const responseData =
    data?.data?.data?.response_data?.map((el, idx) => {
      return {
        ...el,
        id: idx + 1,
      };
    }) || [];
  const totalRecords = data?.data?.data?.total_records;

  return {
    isLoading,
    error,
    responseData,
    isError,
    refetch,
    totalRecords,
  };
}
