import React from "react";
import Filters from "./Filters";
import Table from "./Table";
import { useBuildings } from "../../../../../reactQuery/building";
import BackButton from "../../../../../components/BackButton";

function Ticket() {
  const { responseData } = useBuildings();
  return (
    <div className=" ticket-page">
      <div className="d-flex justify-content-space-between mb-4">
        <div></div>
        <BackButton url={"/"} />
      </div>
      <Filters />
      <Table />
    </div>
  );
}

export default Ticket;
