import {
  Alert,
  Box,
  Button,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import { login } from "../../sevices/apiAuth";
// import GraphicElements from "../../styles/svg/graphic_elements.svg";
import Logo from "../../../styles/svg/Logo.svg";
import { useDispatch } from "react-redux";
import { forgotUser, loginUser } from "../../../sevices/authApi";
// import { user } from "../../redux/usersSlice";
import image from "../../../styles/img/auth-image.png";
import LeftArrow from "../../../styles/svg/left-arrow.svg";
import "../Auth.scss";

function ForgotPassword() {
  const navigate = useNavigate();
  const [apiError, setApiError] = React.useState("");
  const [apiSuccess, setApiSuccess] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { register, handleSubmit, reset, formState } = useForm({
    defaultValues: {},
  });
  const { errors } = formState;

  //Current Url
  const url = localStorage.getItem("redirectLocation");

  const submit = (data) => {
    /**
     * Forgot User
     */
    setApiError("");
    setLoading(true);
    // const formData = createFormData(data);

    forgotUser(data)
      .then((res) => {

        let data = res;

        reset();
        setApiSuccess(res.data.message);
      })
      .catch((err) => {
        console.log(err);
        if (err.code == "ERR_NETWORK") {
          setApiError("An unexpected error occurred. Please try again later");
        } else setApiError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function onError(errors) {
    console.log(errors);
  }

  return (
    <Box
      component="main"
      // sx={{ backgroundImage: `url(${BgSvg})` }}
      sx={{
        height: "100vh",
        display: "flex",
        // justifyContent: "center",
        alignItems: "center",
        // backgroundImage: `url(${GraphicElements})`,
        // backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        // backgroundPosition: "center",
      }}
      disableGutters={true}
    >
      <Box
        sx={{
          // display: "flex",
          // flexDirection: "column",
          // alignItems: "center",
          maxWidth: "400px",
          // justifyContent: "center",
          // height: "100%",
          padding: "0px 40px",
        }}
      >
        <img src={Logo} alt="not found" />
        <Box
          component="form"
          onSubmit={handleSubmit(submit, onError)}
          noValidate
          sx={{
            marginTop: "60px",
            // padding: "0px 40px",
            borderRadius: "16px",
            // boxShadow: "0px 4px 30px 0px rgba(27, 62, 97, 0.06)",
            background: "#ffffff",
          }}
        >
          <Typography className="page-header-text" component="h1" variant="h5">
            Forgot Password
          </Typography>
          <Typography className="info-text">
            Please enter the email address you use ti sign in to acme.
          </Typography>
          {apiError && <Alert severity="error">{apiError}</Alert>}
          {apiSuccess && <Alert severity="success">{apiSuccess}</Alert>}
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={"Email Address"}
            name="email"
            error={errors?.email ? true : false}
            helperText={errors?.email && errors.email.message}
            autoComplete="email"
            autoFocus
            {...register("email", {
              required: "Email is required",
            })}
            className="primary-input-field"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            className="btn-blue"
            sx={{
              mt: "14px",
              padding: "13px 16px",
              bgcolor: "#ED7047",
              boxShadow: "none",
            }}
            disabled={loading}
          >
            Submit
          </Button>
          <div className="sign-up-bottom-container">
            <p className="sign-up-bottom align-items-center d-flex">
              <img src={LeftArrow} />
              <span onClick={() => navigate("/")}>Back to Login</span>
            </p>
          </div>
        </Box>
      </Box>
      <Box
        sx={{
          flex: 1,
          position: "relative",
          display: { xs: "none", md: "block" },
        }}
        className="right-box"
      >
        <img
          src={image}
          alt="Right side background"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
        <Box
          sx={{
            position: "absolute",
            // top: "50%",
            // left: "50%",
            // transform: "translate(-50%, -50%)",
            // color: "white",
            // textAlign: "center",
          }}
          className="text-overlay"
        >
          <Typography variant="h3" component="div">
            CompliNYC
          </Typography>
          {/* <Typography variant="body1" component="div" mt={2}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Typography> */}
        </Box>
      </Box>
    </Box>
  );
}

export default ForgotPassword;
