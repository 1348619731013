import React from "react";
import Heading from "./components/Heading";
import List from "./components/List";
import "./service.scss";
import { useMediaQuery } from "react-responsive";

function Service() {
  const isMobile = useMediaQuery({ maxWidth: "599px" });
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="1list"
    >
      <div style={{ width: isMobile ? "100%" : "40%" }}>
        <Heading />
        <List />
      </div>
    </div>
  );
}

export default Service;
