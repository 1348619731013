// App.js
import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import TicketForm from "./TicketForm";
import moment from "moment";
import { useBuildings } from "../../../reactQuery/building";

const EditTicket = ({ data, onClose, isReSchedule = false }) => {
  const { responseData } = useBuildings();
  const currentBuilding = responseData.find(
    (el) => el.building_id == data.building_id
  );
  const [formData, setFormData] = useState({
    ...data,
    // scheduled_date: moment
    //   .utc(data.scheduled_date)
    //   .local()
    //   .format("MM/DD/YYYY"),
    superintendent_name: currentBuilding.superintendent_name,
    superintendent_number: currentBuilding.superintendent_number,
    borough: currentBuilding.borough,
    date: data.scheduled_date,
  });

  return (
    <Container>
      {/* <div>
        <p className="schedule-appointment-heading">Schedule Appointment</p>
        <p className="form-instruction">
          Please complete all fields to schedule an appointment for service.
        </p>
      </div> */}
      <TicketForm
        initialValues={formData}
        isEdit={true}
        onClose={onClose}
        isReSchedule={isReSchedule}
      />
    </Container>
  );
};

export default EditTicket;
