import React from "react";
import { Stack } from "@mui/material";

import FilterIcon from "../../../../../styles/svg/filter-icon.svg";
import RadioFilter from "../../../../../components/Radio/RadioFilter";
import { useMediaQuery } from "react-responsive";
import CustomSelectDropdown from "../../../../../components/CustomSelectDropDown";
import { useSearchParams } from "react-router-dom";

function RightFilters() {
  const isMobile = useMediaQuery({ maxWidth: "1290px" });
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedStatus = searchParams.get("status");

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  const options = [
    { value: "Pending", label: "Pending" },
    { value: "Positive", label: "Positive" },
    { value: "No Access", label: "No Access" },
    { value: "Negative", label: "Negative" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Inspected", label: "Inspected" },
  ];

  const removeSearchParams = (paramsToRemove) => {
    // Create a copy of the current search params
    const newSearchParams = new URLSearchParams(searchParams);

    // Remove each parameter in the paramsToRemove array
    paramsToRemove.forEach((param) => {
      newSearchParams.delete(param);
    });

    // Set the updated search params
    setSearchParams(newSearchParams);
  };

  const handleSelectChange = (value) => {
    updateSearchParams("status", value);
  };
  const removeFilter = () => {
    removeSearchParams(["status"]);
  };

  return (
    <div className="d-flex align-items-center">
      {isMobile ? (
        <>
          <div className="d-flex align-items-center">
            <img
              src={FilterIcon}
              style={{ height: "16px", width: "16px" }}
              className="mr-2"
            />
            <CustomSelectDropdown
              options={options}
              label="Status"
              onSelectChange={handleSelectChange}
              defaultValue={selectedStatus}
            />
          </div>
        </>
      ) : (
        <>
          <div>
            {selectedStatus ? (
              <p className="clear-filter" onClick={removeFilter}>
                Clear Filter
              </p>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex align-items-center mr-20">
            <img
              src={FilterIcon}
              style={{ height: "16px", width: "16px" }}
              className="mr-2"
            />
            <p className="filter-text">Filter By:</p>
          </div>
          <div className="d-flex align-items-center">
            <Stack direction="row" spacing={2}>
              <RadioFilter title={"Pending"} value={"Pending"} />
              <RadioFilter title={"Positive"} value={"Positive"} />
              <RadioFilter title={"No Access"} value={"No Access"} />
              <RadioFilter title={"Negative"} value={"Negative"} />
              <RadioFilter title={"Cancelled"} value={"Cancelled"} />
              <RadioFilter title={"Inspected"} value={"Inspected"} />
            </Stack>
          </div>
        </>
      )}
    </div>
  );
}

export default RightFilters;
