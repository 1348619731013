import React from "react";
import BackIcon from "../styles/svg/back-icon.svg";
import { useNavigate } from "react-router-dom";

function BackButton({ url }) {
  const redirect = useNavigate();
  return (
    <div
      className="d-flex align-items-center cursor-pointer"
      onClick={() => (url ? redirect(url) : redirect(-1))}
    >
      <img src={BackIcon} className="mr-1" />
      <p className="back-button">Back</p>
    </div>
  );
}

export default BackButton;
