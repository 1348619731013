import React, { useState } from "react";
import PrimaryButton from "../../../../components/Button/Button";
import useDownloader from "react-use-downloader";
import { downloadMailers, downloadSurveyForm } from "../../../../sevices/leadComplianceApi";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import { Button, CircularProgress } from "@mui/material";
import toast from "react-hot-toast";

function DownloadSurveyCompliance({ complianceId }) {
  const [loading, setLoading] = useState(false);
  const { size, elapsed, percentage, download, cancel, error, isInProgress } =
    useDownloader();

  const downloadMailer = () => {
    setLoading(true);
    downloadSurveyForm({
      year: new Date().getFullYear(),
      compliance_id: complianceId,
    })
      .then((res) => {
        download(
          `${process.env.REACT_APP_BASE_URL}/pdf/${res.data.pdf_file_path}`,
          `survey-${complianceId}.pdf`
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  return (
    <div>
      <PrimaryButton
        variant="contained"
        className="capitalize btn-bg-orange popup-header-button"
        onClick={downloadMailer}
        disabled={loading}
        startIcon={
          loading ? <CircularProgress size={20} color="inherit" /> : null
        }
      >
        {loading ? "Downloading..." : " Download Survey"}
      </PrimaryButton>
    </div>
  );
}

export default DownloadSurveyCompliance;
