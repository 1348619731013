import moment from "moment";

export const dustWipe = (data, additionalData) => {
  return `<!DOCTYPE html>
<html>
<head>
<title>Dust Wipe Affidavits</title>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body style="margin:0; font-size: 16px; line-height: 22px;">
 
<div style="padding: 50px 60px; position: relative; ">
	<h1 style="text-align: center; font-size: 20px; margin-bottom: 30px;">AFFIDAVIT BY DUST WIPE SAMPLER</h1>
	<p>I, <strong style="text-decoration: underline;">${
    data?.inspector_name || ""
  }</strong>, performed the sampling for lead based-paint dust hazard clearance at the premises located at <strong style="text-decoration: underline;">${
    data?.building_address || ""
  }</strong>, <strong style="text-decoration: underline;">${
    additionalData.building_detail?.city || ""
  }</strong>, <strong style="text-decoration: underline;">${
    additionalData.building_detail?.state || ""
  }</strong> <strong style="text-decoration: underline;">${
    additionalData.building_detail?.zip_code || ""
  }</strong>, <strong style="text-decoration: underline;">${
    data.apartment
  }</strong> (Apt) on <strong style="text-decoration: underline;">${moment(
    data.scheduled_date
  ).format("MM-DD-YYYY")}</strong> (date).</p>
	<p>I confirm that I am not related to and am independent of the owner of the multiple dwelling and any individual or firm that performed the work that disturbed or abated lead-based paint. I performed the sampling in accordance with 40 CFR &sect; 745.227 and with 28 RCNY &sect;11-06.</p>
	<p>I have attached a copy of my EPA Certification issued under 40 CFR 745.226, either as Lead Inspector / Risk Assessor or my 6 month Interim Certification under 40 CFR 745.225 from an EPA accredited school to this affidavit.. My EPA certification number is <strong style="text-decoration: underline;">${
    additionalData.inspector.certificate_id
  }</strong>,  and it expires on <strong style="text-decoration: underline;">${moment(
    additionalData.inspector.certificate_expire_date
  ).format("MM-DD-YYYY")}</strong>.</p>
	<p>I have also attached to this affidavit a copy of the EPA certification of my firm (firm name): <strong style="text-decoration: underline;">RAZA ENVIRONMENTAL INC.</strong></p>

	<ul style="list-style: none; padding: 0; margin: 0; margin-top: 50px; display: flex; width: 100%;
    justify-content: center; text-align: center;">
		<li style="width: 40%; margin-right: 10%;">
			<div style="border-bottom: 1px solid #000; height: 50px; display: flex; align-items: flex-end; justify-content: center; font-weight: bold;">${
        data?.inspector_name
      }</div>
			<p>(Print Name)</p>
		</li>
		<li style="width: 40%;">
			<div style="border-bottom: 1px solid #000; height: 50px; display: flex; align-items: flex-end; justify-content: center; font-weight: bold;"></div>
			<p>(Signature)</p>
		</li>
	</ul>

	<div style="margin: 40px 0px; ">
		<p style="margin-bottom: 5px;">*************************************************************************************************************</p>
		<p style="text-align:right; margin-top: 0;"><em>Notary Stamp</em></p>
	</div>


	<div>
		<p style="margin-bottom: 15px;">State of New York, County of <strong style="min-width: 200px; display: inline-block; padding: 0px 5px; border-bottom: 1px solid #000;"></strong></p>
		<p style="margin:0;">Sworn to before me this <strong style="border-bottom: 1px solid #000; min-width: 40px; display: inline-block;"></strong> day of <strong style="border-bottom: 1px solid #000; min-width: 40px; display: inline-block;"></strong>, 20<strong style="border-bottom: 1px solid #000; min-width: 40px; display: inline-block;"></strong></p>

		<ul style="list-style: none; padding: 0; margin: 0; margin-top: 50px; display: flex; width: 100%; justify-content: center; text-align: left;">
			<li style="width: 45%; margin-right: 5%;">
				<div style="border-bottom: 1px solid #000; height: 50px; display: flex; align-items: flex-end; justify-content: flex-start; font-weight: bold;"></div>
				<p style="margin-top:5px">(Notary Print Name)</p>
			</li>
			<li style="width: 45%;">
				<div style="border-bottom: 1px solid #000; height: 50px; display: flex; align-items: flex-end; justify-content: flex-start; font-weight: bold;"></div>
				<p style="margin-top:5px">(Notary Signature)</p>
			</li>
		</ul>
	</div>

	

</div>

</body>
</html>`;
};

export const commonAreas = (data, additionalData) => {
  return `<!DOCTYPE html>
<html >
<head>
<title>Common Area Affidavits</title>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body style="margin:0; font-size: 16px; line-height: 22px;">
 
<div style="padding: 40px 70px; position: relative; ">
	<h1 style="text-align: center; font-size: 20px; margin-bottom: 20px;">AFFIDAVIT BY CERTIFIED INDIVIDUAL<br />WHO PERFORMED INSPECTION (TESTING AND/OR SAMPLING)</h1> 
	<p style=" margin-bottom: 10px; margin-top: 10px;">I, <strong style="text-decoration: underline;">${
    data?.inspector_name || ""
  }</strong>, performed the inspection and testing and/or sampling for lead-based paint by testing all paint and similar surface coating material including varnish, shellac or factory-applied coating (excluding factory-bonded or integrated finishes such as electroplating or electrochemical processes such as anodizing) at the premises located at <strong style="text-decoration: underline;">${
    data?.building_address || ""
  }</strong>, <strong style="text-decoration: underline;">${
    additionalData.building_detail?.city || ""
  }</strong>, <strong style="text-decoration: underline;">${
    additionalData.building_detail?.state || ""
  }</strong> <strong style="text-decoration: underline;">${
    additionalData.building_detail?.zip_code || ""
  }</strong> (address), <strong style="text-decoration: underline;">${
    data.apartment
  }</strong> (unit number) on <strong style="text-decoration: underline;">${moment(
    data.scheduled_date
  ).format("MM-DD-YYYY")}</strong> (date).</p>

	<p style=" margin-bottom: 10px; margin-top: 10px;">I am certified to perform such inspections and testing and/or sampling under Part 745 of Title 40 of the Code of Federal Regulations subparts L and Q. I have read and followed the instructions provided for the application for exemption from Administrative Code &sect; 27-2056.5(a), and I performed the inspection, and testing and/or sampling in accordance with those instructions and Title 40 CFR &sect; 745.227, and Chapter 7 of the U.S. Department of Housing and Urban Development’s Guidelines for the Evaluation and Control of Lead-Based Paint Hazards in Housing, 2nd Edition (July 2012) as applicable to the exemption application, and Administrative Code &sect; 27-2056.5(b), and Title 28 of the Rules of the City of New York &sect;11-08.</p>

	<p style=" margin-bottom: 10px; margin-top: 10px;">I have attached a copy of my EPA certification to this affidavit. My EPA certification number is <strong style="text-decoration: underline;">${
    additionalData.inspector.certificate_id
  }</strong>  and it expires on <strong style="text-decoration: underline;">${moment(
    additionalData.inspector.certificate_expire_date
  ).format("MM-DD-YYYY")}</strong>.</p>
	<p style=" margin-bottom: 10px; margin-top: 10px;">I have also attached to this affidavit a copy of the EPA certification of my firm (firm name): <strong style="text-decoration: underline;">RAZA ENVIRONMENTAL INC.</strong></p>

	<p style=" margin-bottom: 10px; margin-top: 10px;">I have <strong>(check at least one):</strong></p>

	<p style=" margin-bottom: 10px; margin-top: 10px;"><span style="border-bottom: 1px solid #000; min-width: 44px; display: inline-block;height: 20px; text-align: center; top: -2px; position: relative;"></span> Reviewed all building documentation provided to me by the owner which demonstrated a similar construction, painting and maintenance history for all units/common areas and the components therein in support of the testing combinations and, if applicable, in the selection of the dwelling unit(s)/common area(s).  </p>

	<p style=" margin-bottom: 10px; margin-top: 10px;"><span style="border-bottom: 1px solid #000; min-width: 44px; display: inline-block;height: 20px;text-align: center; top: -2px; position: relative;"></span> Tested a sample of surfaces and components in order to determine common construction and paint history in support of the testing combinations and, if applicable, in the selection of the dwelling unit(s)/common area(s). </p>

	<p style=" margin-bottom: 10px; margin-top: 10px;"><strong>OR</strong></p>

	<p style=" margin-bottom: 10px; margin-top: 10px;"><span style="border-bottom: 1px solid #000; min-width: 44px; display: inline-block;height: 20px;text-align: center; top: -2px; position: relative;">${
    `${data?.service_type}`.toLowerCase() == "common halls" ? "X" : ""
  }</span> Tested each dwelling unit and/or common area using a Single Family Housing testing methodology, including testing closets as room equivalents if they were dissimilar from the adjoining room equivalent. </p>

	<p style=" margin-bottom: 10px; margin-top: 10px;">The report of the inspection is annexed to this affidavit. I understand that the determination that I am making includes the following in the above referenced premises <strong>(check at least one):</strong></p>
	<p style="margin:0;"><span style="border-bottom: 1px solid #000; min-width: 44px; display: inline-block;height: 20px;text-align: center; top: -2px; position: relative;"></span> Individual dwelling unit</p>
	<p style="margin:0;"><span style="border-bottom: 1px solid #000; min-width: 44px; display: inline-block;height: 20px;text-align: center; top: -2px; position: relative;"></span> Individual common area</p>
	<p style="margin:0;"><span style="border-bottom: 1px solid #000; min-width: 44px; display: inline-block;height: 20px;text-align: center; top: -2px; position: relative;"></span> Every dwelling unit in a building or complex (sampling method)</p>
	<p style=" margin-bottom: 10px; margin-top: 10px;"><span style="border-bottom: 1px solid #000; min-width: 44px; display: inline-block;height: 20px;text-align: center; top: -2px; position: relative;"></span> Every common area in a building or complex (sampling method)</p> 

	<ul style="list-style: none; padding: 0; margin: 0; margin-top: 0px; display: flex; width: 100%;
    justify-content: center; text-align: center;">
		<li style="width: 40%; margin-right: 10%;">
			<div style="border-bottom: 1px solid #000; height: 50px; display: flex; align-items: flex-end; justify-content: center; font-weight: bold;">${
        data?.inspector_name
      }</div>
			<p style="margin-top:5px;    margin-bottom: 5px;">(Print Name)</p>
		</li>
		<li style="width: 40%;">
			<div style="border-bottom: 1px solid #000; height: 50px; display: flex; align-items: flex-end; justify-content: center; font-weight: bold;"></div>
			<p style="margin-top:5px; margin-bottom: 5px;">(Signature)</p>
		</li>
	</ul>

	<div style="margin: 10px 0px; ">
		<p style="margin-bottom: 0px;">*************************************************************************************************************</p>
		<p style="text-align:right; margin-top: 0;"><em>Notary Stamp</em></p>
	</div>


	<div>
		<p style="margin-bottom: 7px;">State of New York, County of <strong style="min-width: 200px; display: inline-block; padding: 0px 5px; border-bottom: 1px solid #000;"></strong></p>
		<p style="margin:0;">Sworn to before me this <strong style="border-bottom: 1px solid #000; min-width: 40px; display: inline-block;"></strong> day of <strong style="border-bottom: 1px solid #000; min-width: 40px; display: inline-block;"></strong>, 20<strong style="border-bottom: 1px solid #000; min-width: 40px; display: inline-block;"></strong></p>

		<ul style="list-style: none; padding: 0; margin: 0; margin-top: 10px; display: flex; width: 100%; justify-content: center; text-align: left;">
			<li style="width: 47%; margin-right: 6%;">
				<div style="border-bottom: 1px solid #000; height: 50px; display: flex; align-items: flex-end; justify-content: flex-start; font-weight: bold;"></div>
				<p style="margin-top:5px;margin-bottom: 0;">(Notary Print Name)</p>
			</li>
			<li style="width: 47%;">
				<div style="border-bottom: 1px solid #000; height: 50px; display: flex; align-items: flex-end; justify-content: flex-start; font-weight: bold;"></div>
				<p style="margin-top:5px; margin-bottom: 0;">(Notary Signature)</p>
			</li>
		</ul>
	</div>

	<p style="margin-top: 0px;">This affidavit version is required for testing/sampling conducted after 10/1/2021.</p>

</div>

</body>
</html>`;
};

export const entireUnitXRF = (data, additionalData) => {
  return `<!DOCTYPE html>
<html>
  <head>
    <title>Entire Unit XRF Affidavits</title>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  </head>
  <body style="margin: 0; font-size: 16px; line-height: 22px">
    <div style="padding: 40px 70px; position: relative">
      <h1 style="text-align: center; font-size: 20px; margin-bottom: 20px">
        AFFIDAVIT BY CERTIFIED INDIVIDUAL<br />WHO PERFORMED INSPECTION (TESTING
        AND/OR SAMPLING)
      </h1>
      <p style="margin-bottom: 10px; margin-top: 10px">
        I, <strong style="text-decoration: underline">${
          data?.inspector_name || ""
        }</strong>,
        performed the inspection and testing and/or sampling for lead-based
        paint by testing all paint and similar surface coating material
        including varnish, shellac or factory-applied coating (excluding
        factory-bonded or integrated finishes such as electroplating or
        electrochemical processes such as anodizing) at the premises located at
        <strong style="text-decoration: underline">${
          data?.building_address || ""
        }</strong>,
        <strong style="text-decoration: underline">${
          additionalData.building_detail?.city || ""
        }</strong>,
        <strong style="text-decoration: underline">${
          additionalData.building_detail?.state || ""
        }</strong>
        <strong style="text-decoration: underline">${
          additionalData.building_detail?.zip_code || ""
        }</strong> (address),
        <strong style="text-decoration: underline">${
          data.apartment
        } </strong>(Apt)  on
        <strong style="text-decoration: underline">${moment(
          data.scheduled_date
        ).format("MM-DD-YYYY")}</strong> (date).
      </p>
      <p style="margin-bottom: 10px; margin-top: 10px">
        I am certified to perform such inspections and testing and/or sampling
        under Part 745 of Title 40 of the Code of Federal Regulations subparts L
        and Q. I have read and followed the instructions provided for the
        application for exemption from Administrative Code &sect; 27-2056.5(a), and I
        performed the inspection, and testing and/or sampling in accordance with
        those instructions and Title 40 CFR &sect; 745.227, and Chapter 7 of the U.S.
        Department of Housing and Urban Development’s Guidelines for the
        Evaluation and Control of Lead-Based Paint Hazards in Housing, 2nd
        Edition (July 2012) as applicable to the exemption application, and
        Administrative Code &sect; 27-2056.5(b), and Title 28 of the Rules of the
        City of New York &sect;11-08.
      </p>
      <p style="margin-bottom: 10px; margin-top: 10px">
        I have attached a copy of my EPA certification to this affidavit. My EPA
        certification number is
        <strong style="text-decoration: underline">${
          additionalData.inspector.certificate_id
        }</strong> and
        it expires on
        <strong style="text-decoration: underline">${moment(
          additionalData.inspector.certificate_expire_date
        ).format("MM-DD-YYYY")}</strong>.
      </p>
      <p style="margin-bottom: 10px; margin-top: 10px">
        I have also attached to this affidavit a copy of the EPA certification
        of my firm (firm name):
        <strong style="text-decoration: underline"
          >RAZA ENVIRONMENTAL INC.</strong
        >
      </p>

      <p style="margin-bottom: 10px; margin-top: 10px">
        I have <strong>(check at least one):</strong>
      </p>

      <p style="margin-bottom: 10px; margin-top: 10px">
        <span
          style="
            border-bottom: 1px solid #000;
            min-width: 44px;
            display: inline-block;
            height: 20px;
            text-align: center;
            top: -2px;
            position: relative;
          "
        ></span>
        Reviewed all building documentation provided to me by the owner which
        demonstrated a similar construction, painting and maintenance history
        for all units/common areas and the components therein in support of the
        testing combinations and, if applicable, in the selection of the
        dwelling unit(s)/common area(s).
      </p>

      <p style="margin-bottom: 10px; margin-top: 10px">
        <span
          style="
            border-bottom: 1px solid #000;
            min-width: 44px;
            display: inline-block;
            height: 20px;
            text-align: center;
            top: -2px;
            position: relative;
          "
        ></span>
        Tested a sample of surfaces and components in order to determine common
        construction and paint history in support of the testing combinations
        and, if applicable, in the selection of the dwelling unit(s)/common
        area(s).
      </p>

      <p style="margin-bottom: 10px; margin-top: 10px"><strong>OR</strong></p>

      <p style="margin-bottom: 10px; margin-top: 10px">
        <span
          style="
            border-bottom: 1px solid #000;
            min-width: 44px;
            display: inline-block;
            height: 20px;
            text-align: center;
            top: -2px;
            position: relative;
          "
          >✓</span
        >
        Tested each dwelling unit and/or common area using a Single Family
        Housing testing methodology, including testing closets as room
        equivalents if they were dissimilar from the adjoining room equivalent.
      </p>

      <p style="margin-bottom: 10px; margin-top: 10px">
        The report of the inspection is annexed to this affidavit. I understand
        that the determination that I am making includes the following in the
        above referenced premises <strong>(check at least one):</strong>
      </p>
      <p style="margin: 0">
        <span
          style="
            border-bottom: 1px solid #000;
            min-width: 44px;
            display: inline-block;
            height: 20px;
            text-align: center;
            top: -2px;
            position: relative;
          "
          >✓</span
        >
        Individual dwelling unit
      </p>
      <p style="margin: 0">
        <span
          style="
            border-bottom: 1px solid #000;
            min-width: 44px;
            display: inline-block;
            height: 20px;
            text-align: center;
            top: -2px;
            position: relative;
          "
        ></span>
        Individual common area
      </p>
      <p style="margin: 0">
        <span
          style="
            border-bottom: 1px solid #000;
            min-width: 44px;
            display: inline-block;
            height: 20px;
            text-align: center;
            top: -2px;
            position: relative;
          "
        ></span>
        Every dwelling unit in a building or complex (sampling method)
      </p>
      <p style="margin-bottom: 10px; margin-top: 10px">
        <span
          style="
            border-bottom: 1px solid #000;
            min-width: 44px;
            display: inline-block;
            height: 20px;
            text-align: center;
            top: -2px;
            position: relative;
          "
        ></span>
        Every common area in a building or complex (sampling method)
      </p>

      <ul
        style="
          list-style: none;
          padding: 0;
          margin: 0;
          margin-top: 0px;
          display: flex;
          width: 100%;
          justify-content: center;
          text-align: center;
        "
      >
        <li style="width: 40%; margin-right: 10%">
          <div
            style="
              border-bottom: 1px solid #000;
              height: 50px;
              display: flex;
              align-items: flex-end;
              justify-content: center;
              font-weight: bold;
            "
          >${data?.inspector_name}</div>
          <p style="margin-top: 5px; margin-bottom: 5px">(Print Name)</p>
        </li>
        <li style="width: 40%">
          <div
            style="
              border-bottom: 1px solid #000;
              height: 50px;
              display: flex;
              align-items: flex-end;
              justify-content: center;
              font-weight: bold;
            "
          ></div>
          <p style="margin-top: 5px; margin-bottom: 5px">(Signature)</p>
        </li>
      </ul>

      <div style="margin: 10px 0px">
        <p style="margin-bottom: 0px">
          *************************************************************************************************************
        </p>
        <p style="text-align: right; margin-top: 0"><em>Notary Stamp</em></p>
      </div>

      <div>
        <p style="margin-bottom: 7px">
          State of New York, County of
          <strong
            style="
              min-width: 200px;
              display: inline-block;
              padding: 0px 5px;
              border-bottom: 1px solid #000;
            "
            ></strong
          >
        </p>
        <p style="margin: 0">
          Sworn to before me this
          <strong style="border-bottom: 1px solid #000; min-width: 40px; display: inline-block;"></strong> day of
          <strong style="border-bottom: 1px solid #000; min-width: 40px; display: inline-block;"></strong>,
          20<strong style="border-bottom: 1px solid #000; min-width: 40px; display: inline-block;"></strong>
        </p>

        <ul
          style="
            list-style: none;
            padding: 0;
            margin: 0;
            margin-top: 10px;
            display: flex;
            width: 100%;
            justify-content: center;
            text-align: left;
          "
        >
          <li style="width: 47%; margin-right: 6%">
            <div
              style="
                border-bottom: 1px solid #000;
                height: 50px;
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;
                font-weight: bold;
              "
            ></div>
            <p style="margin-top: 5px; margin-bottom: 0">(Notary Print Name)</p>
          </li>
          <li style="width: 47%">
            <div
              style="
                border-bottom: 1px solid #000;
                height: 50px;
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;
                font-weight: bold;
              "
            ></div>
            <p style="margin-top: 5px; margin-bottom: 0">(Notary Signature)</p>
          </li>
        </ul>
      </div>

      <p style="margin-top: 0px">
        This affidavit version is required for testing/sampling conducted after
        10/1/2021.
      </p>
    </div>
  </body>
</html>
`;
};

export const frictionSurface = (data, additionalData) => {
  return `<!DOCTYPE html>
<html >
<head>
<title>Friction Surface Affidavits</title>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body style="margin:0; font-size: 16px; line-height: 22px;">
 
<div style="padding: 50px 60px; position: relative; ">
	<h1 style="text-align: center; font-size: 20px; margin-bottom: 30px;">AFFIDAVIT BY CERTIFIED INDIVIDUAL<br />WHO PERFORMED INSPECTION (TESTING AND/OR SAMPLING)</h1>
	<p>I, <strong style="text-decoration: underline;">${
    data?.inspector_name || ""
  }</strong>, performed the inspection and testing and/or sampling for lead-based paint at the premises located at <strong style="text-decoration: underline;">${
    data?.building_address || ""
  }</strong>, <strong style="text-decoration: underline;">${
    additionalData.building_detail?.city || ""
  }</strong>, <strong style="text-decoration: underline;">${
    additionalData.building_detail?.state || ""
  }</strong> <strong style="text-decoration: underline;">${
    additionalData.building_detail?.zip_code || ""
  }</strong>, <strong style="text-decoration: underline;">${
    data.apartment
  }</strong> (unit number, if applicable) on <strong style="text-decoration: underline;">${moment(
    data.scheduled_date
  ).format("MM-DD-YYYY")}</strong> (date).</p>
	<p>I am certified to perform such inspections and testing and/or sampling under Part 745 of the Title 40 of the Code of Federal Regulations subparts L and Q. I performed the inspection, and testing and/or sampling in accordance Title 40 CFR &sect; 745.227, and Chapter 7 of the U.S. Department of Housing and Urban Development’s Guidelines for the Evaluation and Control of Lead-Based Paint Hazards in Housing, 2nd Edition (July 2012).</p>
	<p>I have attached a copy of my EPA certification to this affidavit. My EPA certification number is <strong style="text-decoration: underline;">${
    additionalData.inspector.certificate_id
  }</strong>  and it expires on <strong style="text-decoration: underline;">${moment(
    additionalData.inspector.certificate_expire_date
  ).format("MM-DD-YYYY")}</strong>.</p>
	<p>I have also attached to this affidavit a copy of the EPA certification of my firm (firm name): <strong style="text-decoration: underline;">RAZA ENVIRONMENTAL INC.</strong></p>

	<ul style="list-style: none; padding: 0; margin: 0; margin-top: 50px; display: flex; width: 100%;
    justify-content: center; text-align: center;">
		<li style="width: 40%; margin-right: 10%;">
			<div style="border-bottom: 1px solid #000; height: 50px; display: flex; align-items: flex-end; justify-content: center; font-weight: bold;">${
        data?.inspector_name || ""
      }</div>
			<p>(Print Name)</p>
		</li>
		<li style="width: 40%;">
			<div style="border-bottom: 1px solid #000; height: 50px; display: flex; align-items: flex-end; justify-content: center; font-weight: bold;"></div>
			<p>(Signature)</p>
		</li>
	</ul>

	<div style="margin: 40px 0px; ">
		<p style="margin-bottom: 5px;">*************************************************************************************************************</p>
		<p style="text-align:right; margin-top: 0;"><em>Notary Stamp</em></p>
	</div>


	<div>
		<p style="margin-bottom: 7px;">State of New York, County of <strong style="min-width: 200px; display: inline-block; padding: 0px 5px; border-bottom: 1px solid #000; "></strong></p>
		<p style="margin:0;">Sworn to before me this <strong style="border-bottom: 1px solid #000; min-width: 40px; display: inline-block;"></strong> day of <strong style="border-bottom: 1px solid #000; min-width: 40px; display: inline-block; "></strong>, 20<strong style="border-bottom: 1px solid #000; min-width: 40px; display: inline-block; "></strong></p>

		<ul style="list-style: none; padding: 0; margin: 0; margin-top: 50px; display: flex; width: 100%; justify-content: center; text-align: left;">
			<li style="width: 45%; margin-right: 5%;">
				<div style="border-bottom: 1px solid #000; height: 50px; display: flex; align-items: flex-end; justify-content: flex-start; font-weight: bold;"></div>
				<p style="margin-top:5px">(Notary Print Name)</p>
			</li>
			<li style="width: 45%;">
				<div style="border-bottom: 1px solid #000; height: 50px; display: flex; align-items: flex-end; justify-content: flex-start; font-weight: bold;"></div>
				<p style="margin-top:5px">(Notary Signature)</p>
			</li>
		</ul>
	</div>

	<p style="margin-top: 40px; text-align:center;">Sample From Provided by the Department of Housing Preservation and Development (HPD) – Rev. July 12, 2021 (Owners may use this sample affidavit for lead- based paint testing and recordkeeping for turnover and Local Law 31 of 2020. This affidavit should not be used for the HPD Exemption Application. Owners who plan to submit testing for the Exemption Application should use the testing affidavit provided with that application.)</p>

</div>

</body>
</html>`;
};

export const paintChip = (data, additionalData) => {
  return `<!DOCTYPE html>
<html>
<head>
<title>Friction Surface Affidavits</title>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body style="margin:0; font-size: 16px; line-height: 22px;">
 
<div style="padding: 50px 60px; position: relative; ">
	<h1 style="text-align: center; font-size: 20px; margin-bottom: 30px;">AFFIDAVIT BY CERTIFIED INDIVIDUAL<br />WHO PERFORMED LEAD-BASED PAINT TESTING</h1>
	<p>I, <strong style="text-decoration: underline;">${
    data?.inspector_name || ""
  } (inspectorName)</strong>, performed the inspection and testing and/or sampling for lead-based paint at the premises located at <strong style="text-decoration: underline;">${
    data?.building_address || ""
  } (street)</strong>, <strong style="text-decoration: underline;">${
    additionalData.building_detail?.city || ""
  } (city)</strong>, <strong style="text-decoration: underline;">NY (state)</strong> <strong style="text-decoration: underline;">${
    additionalData.building_detail?.zip_code || ""
  } (Zip)</strong>, <strong style="text-decoration: underline;">${
    data.apartment
  }</strong> (unit number, if applicable) on <strong style="text-decoration: underline;">${moment(
    data.scheduled_date
  ).format("MM-DD-YYYY")}</strong> (date).</p>
	<p>I am certified to perform such inspections and testing and/or sampling under Part 745 of the Title 40 of the Code of Federal Regulations subparts L and Q. I performed the inspection, and testing and/or sampling in accordance Title 40 CFR &sect; 745.227, and Chapter 7 of the U.S. Department of Housing and Urban Development’s Guidelines for the Evaluation and Control of Lead-Based Paint Hazards in Housing, 2nd Edition (July 2012).</p>
	<p>I have attached a copy of my EPA certification to this affidavit. My EPA certification number is <strong style="text-decoration: underline;">${
    additionalData.inspector.certificate_id
  }</strong>  and it expires on <strong style="text-decoration: underline;">${moment(
    additionalData.inspector.certificate_expire_date
  ).format("MM-DD-YYYY")}</strong>.</p>
	<p>I have also attached to this affidavit a copy of the EPA certification of my firm (firm name): <strong style="text-decoration: underline;">RAZA ENVIRONMENTAL INC.</strong></p>

	<ul style="list-style: none; padding: 0; margin: 0; margin-top: 50px; display: flex; width: 100%;
    justify-content: center; text-align: center;">
		<li style="width: 40%; margin-right: 10%;">
			<div style="border-bottom: 1px solid #000; height: 50px; display: flex; align-items: flex-end; justify-content: center; font-weight: bold;">${
        data?.inspector_name
      }</div>
			<p>(Print Name)</p>
		</li>
		<li style="width: 40%;">
			<div style="border-bottom: 1px solid #000; height: 50px; display: flex; align-items: flex-end; justify-content: center; font-weight: bold;"></div>
			<p>(Signature)</p>
		</li>
	</ul>

	<div style="margin: 40px 0px; ">
		<p style="margin-bottom: 5px;">*************************************************************************************************************</p>
		<p style="text-align:right; margin-top: 0;"><em>Notary Stamp</em></p>
	</div>


	<div>
		<p style="margin-bottom: 7px;">State of New York, County of <strong style="min-width: 200px; display: inline-block; padding: 0px 5px; border-bottom: 1px solid #000;"></strong></p>
		<p style="margin:0;">Sworn to before me this <strong style="border-bottom: 1px solid #000; min-width: 40px; display: inline-block;"></strong> day of <strong style="border-bottom: 1px solid #000; min-width: 40px; display: inline-block;"></strong>, 20<strong style="border-bottom: 1px solid #000; min-width: 40px; display: inline-block;"></strong></p>

		<ul style="list-style: none; padding: 0; margin: 0; margin-top: 50px; display: flex; width: 100%; justify-content: center; text-align: left;">
			<li style="width: 45%; margin-right: 5%;">
				<div style="border-bottom: 1px solid #000; height: 50px; display: flex; align-items: flex-end; justify-content: flex-start; font-weight: bold;"></div>
				<p style="margin-top:5px">(Notary Print Name)</p>
			</li>
			<li style="width: 45%;">
				<div style="border-bottom: 1px solid #000; height: 50px; display: flex; align-items: flex-end; justify-content: flex-start; font-weight: bold;"></div>
				<p style="margin-top:5px">(Notary Signature)</p>
			</li>
		</ul>
	</div>

	<p style="margin-top: 40px; text-align:center;">Sample From Provided by the Department of Housing Preservation and Development (HPD) – Rev. July 12, 2021 (Owners may use this sample affidavit for lead- based paint testing and recordkeeping for turnover and Local Law 31 of 2020. This affidavit should not be used for the HPD Exemption Application. Owners who plan to submit testing for the Exemption Application should use the testing affidavit provided with that application.)</p>

</div>

</body>
</html>`;
};
