import ViewSurvey from "../../../Client/detail/components/Units/ViewSurvey";
import RedirectToClient from "../../../Tickets/components/RedirectToClient";
import InspectionScheduled from "./InspectionScheduled";
import Green from "../../../../styles/svg/compliance-green.svg";
import Red from "../../../../styles/svg/red-cross-icon.svg";

export const columns = [
  {
    field: "client",
    headerName: "Client",
    renderCell: (params) => {
      return <RedirectToClient params={params} />;
    },
    minWidth: 100,
    flex: 1,
    // editable: true,
    sortable: false,
  },
  {
    field: "building_address",
    headerName: "Building",
    renderCell: (params) => {
      return <p className="">{params.value}</p>;
    },
    minWidth: 110,
    flex: 1.5,
    // editable: true,
    sortable: false,
  },

  {
    field: "unit_number",
    headerName: "Unit",
    renderCell: (params) => {
      return <p className="unit-ticket">{params.value}</p>;
    },
    minWidth: 100,
    flex: 0.7,
    // editable: true,
    sortable: false,
  },
  {
    field: "mailer_count",
    headerName: "Mailers Send",
    renderCell: (params) => {
      return <p className="">{params.value}</p>;
    },
    minWidth: 100,
    flex: 0.7,
    // editable: true,
    sortable: false,
  },
  {
    field: "window_guards_installed",
    headerName: "Window Guards",
    renderCell: (params) => {
      return params.row.compliance?.status == "pending" ? (
        ""
      ) : params.row?.compliance?.window_guards_installed == false ||
        params.row?.compliance?.window_guards_installed_anyway == true ||
        params.row?.compliance?.window_guards_need_repair_existing == true ? (
        <div className="d-flex align-items-center">
          <img src={Red} />
          <p className="ml-1 text-red">Required</p>
        </div>
      ) : (
        <div className="d-flex align-items-center">
          <img src={Green} />
          <p className="ml-1 text-green">Not Required</p>
        </div>
      );
    },
    minWidth: 100,
    flex: 0.7,
    // editable: true,
    sortable: false,
  },
  {
    field: "knob_covers",
    headerName: "Knob Covers",
    renderCell: (params) => {
      return params.row.compliance?.status == "pending" ? (
        ""
      ) : params.row?.compliance?.stove_knob_covers == true ||
        params.row?.compliance?.permanent_stove_safety_knobs == true ? (
        <div className="d-flex align-items-center">
          <img src={Red} />
          <p className="ml-1 text-red">Required</p>
        </div>
      ) : (
        <div className="d-flex align-items-center">
          <img src={Green} />
          <p className="ml-1 text-green">Not Required</p>
        </div>
      );
    },
    minWidth: 100,
    flex: 0.7,
    // editable: true,
    sortable: false,
  },
  {
    field: "inspection",
    headerName: "Lead Inspection",
    renderCell: (params) => {
      return (
        <>
          <InspectionScheduled
            params={params}
            key={`schedule_inspection_${params.row?.compliance?.compliance_id}`}
          />
        </>
      );
    },
    renderHeader: (cell) => (
      <div className="w-100 d-flex justify-content-center">
        <p className="size-14 text-color weight-500">Lead Inspection</p>
      </div>
    ),
    minWidth: 100,
    flex: 0.7,
    // editable: true,
    sortable: false,
    headerClassName: "center-header",
  },
  {
    field: "survey",
    headerName: "Survey",
    renderCell: (params) => {
      return <ViewSurvey params={params} />;
    },
    renderHeader: (cell) => (
      <div className="w-100 d-flex justify-content-center">
        <p className="size-14 text-color weight-500">Survey</p>
      </div>
    ),
    minWidth: 110,
    flex: 0.8,
    // editable: true,
    sortable: false,
    headerClassName: "center-header ",

  },
];
