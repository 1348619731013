import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import BuildingForm from "./BuildingForm";

function CreateBuilding({ onClose, id, clientName }) {
  const [formData, setFormData] = useState({
    client_id: id,
    client: clientName,
    state: "New York",
    apartments: [
      { unit_number: "Common Hall", unit_size: "0", is_exempt: false },
    ],
  });

  return (
    <Container>
      <BuildingForm initialValues={formData} onClose={onClose} />
    </Container>
  );
}

export default CreateBuilding;
