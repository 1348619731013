import React, { useState } from "react";
import PrimaryButton from "../../../../../components/Button/Button";
import LongFormDialog from "../../../../../components/Dialog/LongFormDialog";
import CreateTicket from "../../../../Tickets/components/CreateTicket";
import EditTicket from "../../../../Tickets/components/EditTicket";
import { useBuildings } from "../../../../../reactQuery/building";

function Schedule({ params }) {
  const [anchorEl, setAnchorEl] = useState(false);
  const { responseData } = useBuildings();

  const handleClose = () => {
    setAnchorEl(false);
  };
  return (
    <div>
      {anchorEl && (
        <LongFormDialog
          open={anchorEl}
          handleClose={handleClose}
          title={"Update Ticket"}
          btnTitle={"Confirm"}
        >
          <EditTicket
            onClose={handleClose}
            data={{
              ...params.row,
              scheduled_date: "",
              report_status: "Pending",
            }}
            isReSchedule={true}
          />
        </LongFormDialog>
      )}
      <PrimaryButton
        variant="contained"
        className={`capitalize btn-bg-orange`}
        onClick={() => setAnchorEl(true)}
      >
        Re-Schedule
      </PrimaryButton>
    </div>
  );
}

export default Schedule;
