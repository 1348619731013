export const fields = [
  {
    name: "child_under_5",
    engText:
      "A child 6 years or younger lives in my apartment or routinely spends 10 or more hours each week in my apartment",
    spnText:
      "Un niño de 6 años o menos vive en mi apartamento o gasta regularmente 10 o más horas cada semana en mi apartamento",
    children: [],
  },
  {
    name: "child_under_10",
    engText: " A child 10 years or younger lives in my apartment and:",
    spnText: "Un niño de 10 años o menos vive en mi apartamento y:",
    children: [
      {
        name: "window_guards_installed",
        value: true,
        engText: " Window guards are installed in all windows as required.",
        spnText:
          "Las rejas de seguridad obligatorias han sido instaladas en todas las ventanas",
      },
      // {
      //   name: "window_guards_need_repair",
      //   engText: "Window guards need repair.",
      //   spnText: "Las rejas de seguridad necesitan reparación",
      // },
      {
        name: "window_guards_installed",
        value: false,
        engText: "Window guards are NOT installed in all windows as required.",
        spnText: "Las rejas de seguridad obligatorias NO han sido instaladas",
      },
    ],
  },
  {
    name: "child_under_10_not_living",
    engText: " No child 10 years or younger lives in my apartment.",
    spnText: "Ningún niño de 10 años o menos vive en mi apartamento",
    children: [
      {
        name: "window_guards_installed_anyway",
        value: true,
        engText: "I want window guards installed anyway.",
        spnText:
          "Quiero tener las rejas de seguridad instaladas de todos modos",
      },
      {
        name: "window_guards_installed_anyway",
        value: false,
        engText: "I have window guards but they need repair.",
        spnText: "Tengo las rejas de seguridad, pero necesitan reparación",
      },
    ],
  },
];
//window_guards_need_repair_existing
export const surveyDetailsFields = [
  { name: "first_name", label: "First Name", type: "text" },
  { name: "last_name", label: "Last Name", type: "text" },
  { name: "phone_number", label: "Telephone #", type: "number" },
  { name: "dates", label: "Date (Fecha)", type: "date" },
];
