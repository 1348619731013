import React, { useState } from "react";
import { addLeadCompliance } from "../../../../../sevices/leadComplianceApi";
import useCustomMutate from "../../../../../hooks/useMutate";

function MailerProgram({ data }) {
  const [loading,setLoading] = useState(false)
  const { mutate } = useCustomMutate(addLeadCompliance, ["building", "units"],()=> {setLoading(false)});
  const onChange = (event) => {
    setLoading(true);
    mutate({
      building_id: data.building_id,
      year: new Date().getFullYear(),
      mailer_program: event.target.checked,
    });
  };
  return (
    <div>
      <label class="custom-checkbox-large">
        <input
          type="checkbox"
          id={`assign-checkbox-${data?.building_id}`}
          onClick={onChange}
          defaultChecked={data?.mailer_program}
          // checked={data.mailer_program ? true : false}
          disabled={loading}
        />
        <span
          class="checkmark-large"
          //   style={{ backgroundColor: `${!inspector ? "#ebebeb" : ""}` }}
        ></span>
      </label>
    </div>
  );
}

export default MailerProgram;
