import React from "react";
import Private from "./Private";
import Calendar from "../../pages/Calendar";
import Tickets from "../../pages/Tickets";
import Dashboard from "../../pages/Dashboard";
import Service from "../../pages/services";
import Terms from "../../pages/Terms";
import InspectorAssign from "../../pages/InspectorAssign";
import InspectorModule from "../../pages/InspectorModule";
import InspectorSchedule from "../../pages/InspectorModule/InspectorSchedule";
import Client from "../../pages/Client";
import ClientDetail from "../../pages/Client/detail";
import ClientTickets from "../../pages/Client/detail/components/Tickets";
import BuildingDetail from "../../pages/Client/detail/components/Units";
import UnitTickets from "../../pages/Client/detail/components/UnitTickets";
import InspectorRole from "../../pages/InspectorRole";
import RouterErrorPage from "../../components/ErrorBoundary";
import ErrorFallback from "../../components/ErrorFallback";
import LeadCompliance from "../../pages/LeadCompliance";

export const Pages = {
  element: <Private />,
  children: [
    {
      element: <Dashboard />,
      errorElement: <ErrorFallback />,
      children: [
        {
          path: "/calendar",
          element: <Calendar />,
        },
        {
          path: "/ticket",
          element: <Tickets />,
        },
        {
          path: "ticket/inspector-assign",
          element: <InspectorAssign />,
        },
        {
          path: "/client",
          // element: <Client />,
          element: (
            <div className="loader-center">
              <p className="orange-color font-family-700">Coming Soon</p>
            </div>
          ),
        },
        {
          path: "/client/:clientId",
          element: <ClientDetail />,
        },
        {
          path: "/client/:clientId/building/:buildingId",
          element: <BuildingDetail />,
        },
        {
          path: "/client/:clientId/building/:buildingId/unit/:unitId",
          element: <UnitTickets />,
        },
        {
          path: "/client/:clientId/tickets",
          element: <ClientTickets />,
        },
        {
          path: "/inspector",
          element: <InspectorModule />,
          // element: (
          //   <div className="loader-center">
          //     <p className="orange-color font-family-700">Coming Soon</p>
          //   </div>
          // ),
        },
        {
          path: "/inspector/:inspectorId",
          element: <InspectorSchedule />,
        },
        {
          path: "/service",
          element: <Service />,
        },
        {
          path: "/terms",
          element: <Terms />,
          // element: (
          //   <div className="loader-center">
          //     <p className="orange-color font-family-700">Coming Soon</p>
          //   </div>
          // ),
        },
        {
          path: "/inspector-role",
          element: <InspectorRole />,
        },
        {
          path: "/lead-compliance",
          element: <LeadCompliance />,
        },
      ],
    },
  ],
};
