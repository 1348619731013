import React, { useEffect, useState } from 'react'
import moment from 'moment';
import * as XLSX from "xlsx";
import {  CircularProgress } from "@mui/material";
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

import SelectDatePicker from '../../../../../components/SelectDatePicker';
import { useCustomSearchParams } from '../../../../../hooks/useCustomSearchParams';
import PrimaryButton from '../../../../../components/Button/Button';
import { downloadExcelReport } from '../../../../../sevices/jobApi';

function DownloadExcel({ onClose }) {
  const params = useParams();
  const [loading,setLoading]= useState(false);
  const [date, setDate, remove] = useCustomSearchParams("date");

  const exportToExcel = () => {
    setLoading(true);
    const createExcelObject = (data) => {
      const exportObject = data?.map((el) => {
        return {
          Date: moment(el.scheduled_date).format("MM-DD-YYYY"),
          Building_address: el.building_address,
          Apartment: el.apartment,
          Service: el.service_type,
          Occupancy_Status: el.unit_status,
          Report_Status: el.report_status,
        };
      });

      const worksheet = XLSX.utils.json_to_sheet(exportObject); // Convert JSON data to worksheet
      const workbook = XLSX.utils.book_new(); // Create a new workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1"); // Append the worksheet
      XLSX.writeFile(workbook, `${data?.[0].client_name}.xlsx`); // Export the workbook to an Excel file
      onClose();
      setLoading(false);
    };

    downloadExcelReport({
      date: JSON.parse(date),
      clientId: params.clientId,
    })
      .then((res) => {
        if (res?.data?.data?.total_records) {
          createExcelObject(res?.data?.data?.response_data);
        } else {
          toast.error("No Records found");
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });

    
  };

  useEffect(() => {
    setDate(
      "date",
      JSON.stringify({
        key: "selection",
        startDate: moment().subtract(6, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      })
    );
  }, []);
  return (
    <div className="d-flex mb-6">
      <SelectDatePicker />
      <PrimaryButton
        variant="contained"
        className={`capitalize btn-bg-orange ml-1`}
        onClick={exportToExcel}
        startIcon={
          loading ? <CircularProgress size={20} color="inherit" /> : null
        }
      >
        {loading ? "Downloading..." : "Download Excel"}
      </PrimaryButton>
    </div>
  );
}

export default DownloadExcel