import React, { useState } from 'react'
import PrimaryButton from '../../../../../components/Button/Button';
import FormDialog from '../../../../../components/Dialog/FormDialogSec';
import DownloadExcel from './DownloadExcel';

function WeeklyReport() {
     const [anchorEl, setAnchorEl] = useState(false);

     const handleClose = () => {
       setAnchorEl(false);
     };

  return (
    <div className="mt-4 d-flex justify-content-end">
      {anchorEl && (
        <FormDialog
          open={anchorEl}
          handleClose={handleClose}
          title={"Excel Report"}
          btnTitle={"Confirm"}
        >
          <DownloadExcel onClose={handleClose} />
        </FormDialog>
      )}
      <PrimaryButton
        variant="contained"
        className={`capitalize btn-bg-orange`}
        onClick={() => setAnchorEl(true)}
      >
        Weekly Report
      </PrimaryButton>
    </div>
  );
}

export default WeeklyReport