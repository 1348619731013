import { useQuery } from "@tanstack/react-query";
import { getCannedMessage, getChats } from "../sevices/chatApi";

export function useChat(id) {
  const { isLoading, data, error, isError, refetch, isRefetching } = useQuery({
    queryKey: ["chat", id],
    queryFn: () => getChats(id),
  });

  const responseData = data?.data?.data?.response_data;

  return { isLoading, error, responseData, isError, refetch, isRefetching };
}
//getCannedMessage

export function useCanned(id) {
  const { isLoading, data, error, isError, refetch } = useQuery({
    queryKey: ["canned", id],
    queryFn: () => getCannedMessage(id),
  });

  const responseData = data?.data?.data?.response_data;

  return { isLoading, error, responseData, isError, refetch };
}
