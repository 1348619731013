import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import Green from "../../../../styles/svg/compliance-green.svg";
import Red from "../../../../styles/svg/red-cross-icon.svg";
import PrimaryButton from "../../../../components/Button/Button";
import LongFormDialog from "../../../../components/Dialog/LongFormDialog";
import TicketForm from "../../../Tickets/components/TicketForm";
import { useBuildings } from "../../../../reactQuery/building";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";

function InspectionScheduled({ params, isFromUnit = false }) {
  const { responseData, unitStatus, totalRecords } = useBuildings();
  const [refetch, setRefetch] = useCustomSearchParams("refetch");
  const [formData, setFormData] = useState({
    scheduled_date: "",
    service_id: "",
    building_id: params.row?.building_id,
    apartment: params?.row?.unit_number,
    unit_id: params?.row?.compliance?.unit_id,
    unit_status: "",
    borough: "",
    superintendent_name: "",
    superintendent_number: "",
    additional_comment: "",
    is_flexible: false,
    is_prior: false,
    compliance_id: params.row?.compliance?.compliance_id,
  });
  const [anchorEl, setAnchorEl] = useState(false);

  const handleClose = () => {
    setAnchorEl(false);
    setRefetch("refetch", refetch ? refetch + 1 : 1);
  };
  const handleClick = () => {
    setAnchorEl(true);
  };

  useEffect(() => {
    if (totalRecords && !isFromUnit) {
      const currentBuilding = responseData?.find(
        (el) => el.building_id == params.row?.building_id
      );
      const apt = currentBuilding?.units;

      const currentApt = apt?.find(
        (el) => el.unit_id == params?.row?.compliance?.unit_id
      );

      setFormData((data) => {
        return {
          ...data,
          unit_size: currentApt?.unit_size,
          // unit_id: currentApt?.unit_id,
          unit_status: currentApt?.unit_status,
          borough: currentBuilding?.borough || "",
          superintendent_name: currentBuilding?.superintendent_name || "",
          superintendent_number: currentBuilding?.superintendent_number || "",
        };
      });
    }
  }, [totalRecords]);
  return (
    <>
      {anchorEl && (
        <LongFormDialog
          open={anchorEl}
          handleClose={handleClose}
          title={"Create Ticket"}
          btnTitle={"Confirm"}
        >
          <Container>
            <div className="mb-32">
              <p className="schedule-appointment-heading">
                Schedule Appointment
              </p>
              <p className="form-instruction">
                Please complete all fields to schedule an appointment for
                service.
              </p>
            </div>
            <TicketForm initialValues={formData} onClose={handleClose} />
          </Container>
        </LongFormDialog>
      )}
      <div className="w-100 d-flex justify-content-center">
        {params.row.compliance?.status == "pending" ||
        params.row.compliance?.status == null ? (
          ""
        ) : params.row.compliance?.status == "inspection_scheduled" ? (
          // <img src={Green} />
          <p>Scheduled</p>
        ) : params.row.compliance?.status == "inspection_needed" ? (
          isFromUnit ? (
            <div className="d-flex align-items-center">
              <img src={Red} />
              <p className="ml-1 text-red">Required</p>
            </div>
          ) : (
            <PrimaryButton
              variant="contained"
              className={`capitalize btn-bg-orange`}
              onClick={handleClick}
            >
              Schedule
            </PrimaryButton>
          )
        ) : params.row.compliance?.status == "completed" ? (
          params.row.compliance?.child_under_5 == true ||
          params.row.compliance?.child_under_6 == true ? (
            <p>Inspection Cleared</p>
          ) : params.row.compliance?.child_under_10_not_living == true || params.row.compliance?.child_under_10 == true ||
            params.row.compliance?.child_under_6 == false ? (
            <div className="d-flex align-items-center">
              <img src={Green} />
              <p className="ml-1 text-green">Not Required</p>
            </div>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default InspectionScheduled;
