import React, { useState } from "react";
import viewIcon from "../../../../../styles/svg/view-docs.svg";
import { downloadReport } from "../../../../../sevices/jobApi";
import LongFormDialog from "../../../../../components/Dialog/LongFormDialog";
import ViewFile from "../../../../../components/ViewFile";

function ViewDocument({ params, icon = false, jobId = null }) {
  //params.row.job_data.job_id
  const [anchorEl, setAnchorEl] = useState(false);
  const [url, setUrl] = useState("");

  const handleClose = () => {
    setAnchorEl(false);
  };

  const handleClick = () => {
    setAnchorEl(true);
  };

  const downloadFile = () => {
    downloadReport(jobId ? jobId : params.row.job_id)
      .then((res) => {
        // window.open(
        //   `${process.env.REACT_APP_BASE_URL}${res.data.data.file_url}`,
        //   "_blank"
        // );
        setUrl({
          url: `${res.data.data.file_url}`.replaceAll("#", "%23"),
          name: res.data.data.file_name,
        });
        handleClick();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      {anchorEl && (
        <LongFormDialog
          open={anchorEl}
          handleClose={handleClose}
          title={"Report File"}
          btnTitle={"Confirm"}
          isDownloadButton={true}
          file={url}
        >
          <ViewFile url={url.url} />
        </LongFormDialog>
      )}
      <img
        src={icon ? icon : viewIcon}
        className="mr-1 cursor-pointer"
        onClick={downloadFile}
      />
    </>
  );
}

export default ViewDocument;
