import React, { useState } from "react";

function ToggleInput({ name, setValue, defaultValue }) {
  const [status, setStatus] = useState(defaultValue);
  return (
    <>
      <label className="switch">
        <input
          type="checkbox"
          value={status}
          checked={status}
          // defaultValue={defaultValue}
          onChange={(e) => {
            setValue(name, !status);
            setStatus(!status);
          }}
        />
        <div
          className={`slider d-flex align-items-center ${
            status ? "" : "justify-content-end"
          }`}
        >
          {status ? (
            <p className="clr-white ml-2">on</p>
          ) : (
            <p className="clr-white mr-2">off</p>
          )}
        </div>
      </label>
    </>
  );
}

export default ToggleInput;
