// App.js
import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import ClientForm from "./ClientForm";
import { formatPhoneNumber } from "../../../utils/helpers";

const EditClient = ({ data, onClose }) => {
  const [formData, setFormData] = useState({
    email: data.user.email,
    name: data.user.name,
    position: data.user.position,
    phone_number: formatPhoneNumber(data.user.phone_number),
    company_name: data.company_name,
    status: data.status,
    zip_code: data.zip_code,
    state: data.state,
    city: data.city,
    address: data.address,
    street_address: data.street_address,
    password: "",
    confirm_password: "",
    xrf_single_unit_testing_rate: data.xrf_single_unit_testing_rate,
    xrf_volume_discount_rate: data.xrf_volume_discount_rate,
    id: data.client_id,
  });

  return (
    <Container>
      <ClientForm initialValues={formData} isEdit={true} onClose={onClose} />
    </Container>
  );
};

export default EditClient;
