import Api from "./axios";

export const getServices = async (data) => {
  return await Api.get(`/v1/service`);
};

export const createService = async (data) => {
  return await Api.post(`/v1/service`, data);
};

export const updateService = async ({ id, data }) => {
  return await Api.put(`/v1/service/${id}`, data);
};

export const updateSequence = async (data) => {
  return await Api.post(`/v1/service/sequence-update`, data);
};
