import moment from "moment";

import downloadIcon from "../../../../../styles/svg/download-icon.svg";
import EditIcon from "../../../../Tickets/components/EditIcon";
import ReportDownload from "../../../../Tickets/components/ReportDownload";
import ViewDocument from "../RecentUploads/ViewDocument";
import Schedule from "../Noaccess/Schedule";
// import EditIcon from "./EditIcon";

export const columns = [
  {
    field: "scheduled_date",
    headerName: "Date",
    renderCell: (params) => {
      return <p>{moment(params.value).format("MM/DD/YYYY")}</p>;
    },
    minWidth: 130,
    flex: 1,
    // editable: true,
    sortable: false,
  },
  {
    field: "building_address",
    headerName: "Building",
    renderCell: (params) => {
      return <p className="">{params.value}</p>;
    },
    minWidth: 110,
    flex: 1.5,
    // editable: true,
  },
  {
    field: "apartment",
    headerName: "Apt#",
    renderCell: (params) => {
      return <p className="unit-ticket">{params.value}</p>;
    },
    minWidth: 80,
    flex: 0.5,
    // editable: true,
    sortable: false,
  },
  {
    field: "unit_size",
    headerName: "Unit Size",
    renderCell: (params) => {
      return <p>{params.value}</p>;
    },
    minWidth: 100,
    flex: 0.7,
    // editable: true,
    sortable: false,
  },
  {
    field: "service_type",
    headerName: "Service Type",
    renderCell: (params) => {
      return (
        <p
          className={`${params.value ? "status-chip" : ""}`}
          style={{ backgroundColor: params.row.service_icon || "#11b50c" }}
        >
          {params.value}
        </p>
      );
    },
    minWidth: 198,
    flex: 1.2,
    // editable: true,
    sortable: false,
  },

  {
    field: "report_status",
    headerName: "Status",
    renderCell: (params) => {
      //status_colour
      return (
        <p
          className={`${params.value ? "status-chip" : ""}`}
          style={{ backgroundColor: params.row.status_colour || "#11b50c" }}
        >
          {params.value}
        </p>
      );
    },
    minWidth: 120,
    flex: 0.9,
    // editable: true,
    sortable: false,
  },
  {
    field: "pending-action",
    headerName: "Action",
    renderCell: (params) => {
      return (
        <div className="d-flex">
          <EditIcon params={params} />
        </div>
      );
    },
    minWidth: 100,
    flex: 0.9,
    // editable: true,
    sortable: false,
  },
  {
    field: "noaccess-action",
    headerName: "Action",
    renderCell: (params) => {
      return <Schedule params={params} />;
    },
    minWidth: 100,
    flex: 0.9,
    // editable: true,
    sortable: false,
  },
  {
    field: "complete-action",
    headerName: "Action",
    renderCell: (params) => {
      return (
        <div className="d-flex">
          {params.row?.report_available ? (
            // <ReportDownload params={params} idClient={true} />
            <ViewDocument params={params} />
          ) : (
            ""
          )}
        </div>
      );
    },
    minWidth: 100,
    flex: 0.9,
    // editable: true,
    sortable: false,
  },
];
