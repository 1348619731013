import React from 'react'
import ComplianceYear from '../../../../LeadCompliance/components/Header/ComplianceYear';
import DownloadAuditReport from './DownloadAuditReport';

function LeadComplianceHeading({ building, client, isGrey }) {
  return (
    <div
      className={`${
        isGrey
          ? "lead-compliance-unit-table-gred"
          : "lead-compliance-unit-table"
      }  d-flex  align-items-center`}
    >
      <ComplianceYear name={"Annual Compliance"} />
      <DownloadAuditReport building={building} client={client} />
    </div>
  );
}

export default LeadComplianceHeading