import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  count: {},
};

const compliance = createSlice({
  name: "compliance",
  initialState,
  reducers: {
    setStatusCount: {
      prepare(count) {
        return {
          payload: { count },
        };
      },
      reducer(state, action) {
        state.count = action.payload.count;
      },
    },
  },
});

export const { setStatusCount } = compliance.actions;

export default compliance.reducer;
