import * as React from "react";
import { styled } from "@mui/material/styles";
import { Button, FormHelperText } from "@mui/material";
import UploadSurvey from "../../../../styles/svg/upload-survey.svg";
import PrimaryButton from "../../../../components/Button/Button";
import { truncateText } from "../../../../utils/helpers";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function InputFileUpload({
  register,
  getValues,
  watch,
  errors,
  isRequiredUpload,
}) {
  // Watch all fields and trigger onChange event
  const watchedFields = watch("attachment");

  return (
    <div>
      <PrimaryButton
        component="label"
        role={undefined}
        tabIndex={-1}
        variant="contained"
        className={`capitalize btn-bg-orange `}
        key={"form-2"}
        // onClick={handleClick}
        startIcon={<img src={UploadSurvey} />}
      >
        {watchedFields?.[0]
          ? truncateText(`${watchedFields?.[0]?.name}`, 14)
          : "Upload Form"}
        <VisuallyHiddenInput
          type="file"
          accept="image/jpeg"
          {...register(
            "attachment",
            isRequiredUpload && {
              required: `Upload Form is required`,
            }
          )}
        />
      </PrimaryButton>
      <FormHelperText sx={{ color: "#d32f2f" }}>
        {errors?.["attachment"] && errors?.["attachment"].message}
      </FormHelperText>
    </div>
  );
}
