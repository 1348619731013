import { servicesVariables } from "../utils/constants";
import Api from "./axios";

export const getBuilding = async (data) => {
  const role = JSON.parse(localStorage.getItem("userData")).role;
  const id =
    data?.clientId && role == "admin"
      ? `?client_id=${data.clientId}&service_id=${servicesVariables.xrfEnitrUnitNegative}`
      : `?service_id=${servicesVariables.xrfEnitrUnitNegative}`;
  return await Api.get(`/v1/building${id}`);
};

export const getBuildingOfClient = async (data) => {
  const role = JSON.parse(localStorage.getItem("userData")).role;
  const id =
    data?.clientId && role == "admin"
      ? `&client_id=${data.clientId}&service_id=${servicesVariables.xrfEnitrUnitNegative}`
      : `&service_id=${servicesVariables.xrfEnitrUnitNegative}`;
  return await Api.get(
    `/v1/building?page=${data.page || ""}&page_size=${
      data.pageSize || ""
    }&pagination=${data.pagination}&search=${data.search || ""}${id}`
  );
};

export const getBuildingById = async ({id,year}) => {
  return await Api.get(`/v1/building/${id}?year=${year}`);
};

export const createBuilding = async (data) => {
  return await Api.post(`/v1/building`, data);
};
export const updateBuilding = async ({ data, id }) => {
  return await Api.put(`/v1/building/${id}`, data);
};

export const getBuildingBySurvey = async (survey,token) => {
  return await Api.get(`/v1/compliance/by_survey/${survey}`, {
    headers: token ? { authorization: `Bearer ${token}` } : {},
  });
};
