import Api from "./axios";

export const createClient = async (data) => {
  return await Api.post(`/v1/client/register`, data);
};

export const updateClient = async (data) => {
  return await Api.put(`/v1/client/${data.id}`, data.data);
};

export const getClientDetail = async (id) => {
  return await Api.get(`/v1/client/detail?client_id=${id}`);
};

export const getClient = async (data) => {
  return await Api.get(
    `/v1/client?page=${data?.page || 1}&page_size=${
      data?.pageSize || 100
    }&search=${data?.search || ""}&pagination=${data?.pagination || "false"}${
      data?.sort || ""
    }${data?.status || ""}`
  );
};

export const getClientId = async (id) => {
  return await Api.get(`/v1/client/${id}/building`);
};
