import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useMediaQuery } from "@mui/material";
import PrimaryButton from "../Button/Button";
import useDownloader from "react-use-downloader";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function LongFormDialog({
  open,
  children,
  handleClose,
  callback,
  loading,
  title,
  btnTitle,
  btnColor = "#1B3E61",
  isDownloadButton = false,
  file,
  IsChildren = false,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { size, elapsed, percentage, download, cancel, error, isInProgress } =
    useDownloader();

  const handleClick = () => {
    download(`${process.env.REACT_APP_BASE_URL}${file.url}`, file.name);
  };
  return (
    <React.Fragment>
      <BootstrapDialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        open={open}
        fullScreen={fullScreen}
        sx={{
          height: "100vh",
          "& .MuiPaper-root": {
            borderRadius: "12px",
            maxWidth: "966px",
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, padding: "16px", bgcolor: "#f5f5f9", lineHeight: "1" }}
          id="responsive-dialog-title"
          className="dialog-title justify-content-space-between d-flex"
        >
          {title}
          {isDownloadButton && (
            <PrimaryButton
              variant="contained"
              className="capitalize btn-bg-orange popup-header-button"
              onClick={handleClick}
              // startIcon={<AddIcon />}
            >
              Download
            </PrimaryButton>
          )}
          {IsChildren && IsChildren}
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers className="dialog-content-form ">
          {children}
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
