import React from "react";
import { Stack } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import JobIcon from "../../../styles/svg/calendar-job.svg";
import NotAccessIcon from "../../../styles/svg/not-access.svg";
import RecordsIcon from "../../../styles/svg/uploaded-records-icon.svg";
import CustomPopover from "../../../components/Popover";

const Status = ({ eventInfo }) => {
  const isMobile = useMediaQuery({ maxWidth: "1070px" });
  const dateObj = JSON.parse(eventInfo.event.title);
  const redirect = useNavigate();

  ///popover
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  ////popover

  const dateToCheck = moment(dateObj.scheduled_date);

  // Check if the date is before today
  const isBeforeToday = dateToCheck.isBefore(moment().add(1, "day"), "day");

  const redirectTicketPage = () => {
    const date = {
      key: "selection",
      startDate: moment(dateObj.scheduled_date).format("YYYY-MM-DD"),
      endDate: moment(dateObj.scheduled_date).format("YYYY-MM-DD"),
    };
    const stringDate = JSON.stringify(date);
    redirect(`/ticket?date=${stringDate}`);
  };

  return (
    <div className="cursor-pointer">
      {dateObj.job_count ? (
        isMobile ? (
          <div>
            <div onClick={handleClick} className="cursor-pointer">
              <MoreHorizIcon color="action" />
            </div>
            <CustomPopover
              id={id}
              open={open}
              handleClose={handleClose}
              anchorEl={anchorEl}
            >
              <div
                className="status-detail-box"
                style={
                  isMobile
                    ? {
                        "--status-box-padding": "0px 4px",
                      }
                    : {}
                }
                onClick={redirectTicketPage}
              >
                <Stack direction="row" spacing={1}>
                  <div>
                    <img src={JobIcon} className={isMobile ? "" : "mr-2px"} />
                    <p className="job-count">{dateObj.job_count}</p>
                  </div>
                  {isBeforeToday && (
                    <>
                      <div>
                        <img
                          src={RecordsIcon}
                          className={isMobile ? "" : "mr-2px"}
                        />
                        <p className="job-count">
                          {dateObj.report_uploaded_count}
                        </p>
                      </div>
                      <div>
                        <img
                          src={NotAccessIcon}
                          className={isMobile ? "" : "mr-2px"}
                        />
                        <p className="job-count">
                          {dateObj.access_issue_count}
                        </p>
                      </div>
                    </>
                  )}
                </Stack>
              </div>
            </CustomPopover>
          </div>
        ) : (
          <div
            className="status-detail-box"
            style={
              isMobile
                ? {
                    "--status-box-padding": "0px 4px",
                  }
                : {}
            }
            onClick={redirectTicketPage}
          >
            <Stack direction="row" spacing={1}>
              <div>
                <img src={JobIcon} className={isMobile ? "" : "mr-2px"} />
                <p className="job-count">{dateObj.job_count}</p>
              </div>
              {isBeforeToday && (
                <>
                  <div>
                    <img
                      src={RecordsIcon}
                      className={isMobile ? "" : "mr-2px"}
                    />
                    <p className="job-count">{dateObj.report_uploaded_count}</p>
                  </div>
                  <div>
                    <img
                      src={NotAccessIcon}
                      className={isMobile ? "" : "mr-2px"}
                    />
                    <p className="job-count">{dateObj.access_issue_count}</p>
                  </div>
                </>
              )}
            </Stack>
          </div>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default Status;
