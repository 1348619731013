export const servicesVariables = {
  xrfEnitrUnitNegative: process.env.REACT_APP_XRFENTIRUNIT * 1,
  xrfEnitrUnitPositive: process.env.REACT_APP_XRFENTIRUNIT * 1,
  xrfFrictionPositive: process.env.REACT_APP_XRFFRICTION,
  xrfFrictionNegative: process.env.REACT_APP_XRFFRICTION * 1,
  dustWipe: process.env.REACT_APP_DUSTWIPE * 1,
  paintChip: process.env.REACT_APP_PAINTCHIP * 1,
};

export const showAffedevitForServices = [1, 2, 5];
