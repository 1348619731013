import {
  Alert,
  Box,
  Button,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import { login } from "../../sevices/apiAuth";
// import GraphicElements from "../../styles/svg/graphic_elements.svg";
import Logo from "../../../styles/svg/Logo.svg";
import { useDispatch } from "react-redux";
import { loginUser } from "../../../sevices/authApi";
// import { user } from "../../redux/usersSlice";
import image from "../../../styles/img/auth-image.png";
import "../Auth.scss";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginWithEmail, setLoginWith] = React.useState(false);
  const [apiError, setApiError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { register, handleSubmit, reset, formState } = useForm({
    defaultValues: {},
  });
  const { errors } = formState;
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  //Current Url
  const url = localStorage.getItem("redirectLocation");

  /**
   * Set details to local storage
   */
  const setDetails = (data) => {
    // dispatch(user(data.user_data));
    localStorage.setItem("token", data.access_token);
    // localStorage.setItem("role", data.user_type);
    localStorage.setItem("userData", JSON.stringify(data.user_data));
    // localStorage.setItem({
    //   key: "userId",
    //   value: data.user_data.user_id,
    // });
  };

  /**
   * Create form data
   */
  // function createFormData(data) {
  //   const formData = new FormData();
  //   formData.append("email", data.email);
  //   formData.append("password", data.password);
  //   return formData;
  // }

  const submit = (data) => {
    /**
     * Login User
     */
    setApiError("");
    setLoading(true);
    // const formData = createFormData(data);

    loginUser(data)
      .then((res) => {
        // console.log(res.data.data.responseData[0]);
        let data = res.data.data;
        setDetails(data);
        if (data.user_data.role == "admin") {
          navigate(url || "/calendar");
        } else if (data.user_data.role == "client") {
          navigate(url || `/client/${data.user_data.role_id}`);
        } else if (data.user_data.role == "inspector") {
          navigate(url || `/inspector-role`);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.code == "ERR_NETWORK") {
          setApiError("An unexpected error occurred. Please try again later");
        } else setApiError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function onError(errors) {
    console.log(errors);
  }

  function changeLoginWith() {
    reset();
    setLoginWith((loginWith) => !loginWith);
    setApiError("");
  }

  return (
    <Box
      component="main"
      // sx={{ backgroundImage: `url(${BgSvg})` }}
      sx={{
        height: "100vh",
        display: "flex",
        // justifyContent: "center",
        alignItems: "center",
        // backgroundImage: `url(${GraphicElements})`,
        // backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        // backgroundPosition: "center",
      }}
      disableGutters={true}
    >
      <Box
        sx={{
          // display: "flex",
          // flexDirection: "column",
          // alignItems: "center",
          maxWidth: "400px",
          // justifyContent: "center",
          // height: "100%",
          padding: "0px 40px",
        }}
      >
        <img src={Logo} alt="not found" />
        <Box
          component="form"
          onSubmit={handleSubmit(submit, onError)}
          noValidate
          sx={{
            marginTop: "60px",
            // padding: "0px 40px",
            borderRadius: "16px",
            // boxShadow: "0px 4px 30px 0px rgba(27, 62, 97, 0.06)",
            background: "#ffffff",
          }}
        >
          <Typography className="page-header-text" component="h1" variant="h5">
            Sign In
          </Typography>
          <Typography className="info-text">
            Enter your email and password to access your account.
          </Typography>
          {apiError && <Alert severity="error">{apiError}</Alert>}
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={"Email Address"}
            name="email"
            error={errors?.email ? true : false}
            helperText={errors?.email && errors.email.message}
            autoComplete="email"
            autoFocus
            {...register("email", {
              required: "Email is required",
            })}
            className="primary-input-field"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            error={errors?.password ? true : false}
            helperText={errors?.password && errors.password.message}
            autoComplete="current-password"
            {...register("password", {
              required: "Password is required",
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <div className="forgot-text">
            <p className="sign-up-bottom">
              <span onClick={() => navigate("/forgot-password")}>
                Forgot Password?
              </span>
            </p>
          </div>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            className="btn-blue"
            sx={{
              mt: "14px",
              padding: "13px 16px",
              bgcolor: "#ED7047",
              boxShadow: "none",
            }}
            disabled={loading}
          >
            Sign In
          </Button>
          <div className="sign-up-bottom-container">
            <p className="sign-up-bottom">
              Don’t have an account?{" "}
              <span onClick={() => navigate("/sign-up")}>Sign Up</span>
            </p>
          </div>
        </Box>
      </Box>
      <Box
        sx={{
          flex: 1,
          position: "relative",
          display: { xs: "none", md: "block" },
        }}
        className="right-box"
      >
        <img
          src={image}
          alt="Right side background"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
        <Box
          sx={{
            position: "absolute",
            // top: "50%",
            // left: "50%",
            // transform: "translate(-50%, -50%)",
            // color: "white",
            // textAlign: "center",
          }}
          className="text-overlay"
        >
          <Typography variant="h3" component="div">
            CompliNYC
          </Typography>
          {/* <Typography variant="body1" component="div" mt={2}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Typography> */}
        </Box>
      </Box>
    </Box>
  );
}

export default Login;
