import React from "react";
import MailerProgram from "../Building/MailerProgram";

function Mailer({ data }) {
  return (
    <div className="d-flex mt-2">
      <MailerProgram data={data} />
      <p className="ml-1 mailer-txt-unit">Include building in mailer program</p>
    </div>
  );
}

export default Mailer;
