import React, { useEffect, useState } from "react";
import { columns } from "./tableColumns";
import CustomTable from "../../../../../components/Table/CustomTable";
import Loader from "../../../../../components/Loader";
import { useCustomParams } from "../../../../../hooks/useCustomParams";
import { useClientBuildings } from "../../../../../reactQuery/building";
import { useParams } from "react-router-dom";
import { Box, Divider } from "@mui/material";

function Table() {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [search] = useCustomParams("search");
  const params = useParams();

  const { isLoading, error, responseData, isError, refetch, totalRecords } =
    useClientBuildings(
      {
        page: page + 1,
        pageSize,
        search,
        clientId: params.clientId,
        pagination: true,
      },
      params.clientId
    );

  useEffect(() => {
    refetch();
  }, [params.clientId,page,pageSize,search]);

  // const filteredBuildings = search
  //   ? responseData?.filter((item) =>
  //       item.address.toLowerCase().includes(search.toLowerCase())
  //     )
  //   : responseData;

  return (
    <>
      {isLoading && (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      )}
      {!isLoading && isError && (
        <div className="loader-center">
          <p>{error?.response?.data?.message || "Error"}</p>
        </div>
      )}
      {!isLoading && !isError && (
        <>
          <CustomTable
            initialRows={responseData}
            columns={columns}
            headerBgColor={"#F5F5F9"}
            rowCount={totalRecords}
            defaultSort={"address"}
            setPaginationModel={(data) => {
              setPage(data.page);
              setPageSize(data.pageSize);
            }}
            page={page}
            pageSize={pageSize}
            subHeight={282}
            // hideFooter={true}
            borderRadius={false}
            useMaxHeight={true}
          />
          <div className="table-bottom">
            <Box className="d-flex">
              <Box className="mr-4">
                <p className="bottom-detail">{responseData.length} Buildings</p>
              </Box>

              <Divider orientation="vertical" flexItem />

              <Box className="ml-4">
                <p className="bottom-detail">
                  {responseData?.reduce(
                    (accumulator, currentValue) =>
                      accumulator + currentValue?.units.length,
                    0
                  )}{" "}
                  Units
                </p>
              </Box>
            </Box>
          </div>
        </>
      )}
    </>
  );
}

export default Table;
