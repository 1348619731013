import React, { useEffect, useState } from "react";
import { Box, Typography, Divider } from "@mui/material";
import { useErrorBoundary } from "react-error-boundary";

import { useClientDetail } from "../../../../reactQuery/client";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../../components/Loader";
import ClientLogo from "../../../../styles/svg/client-logo.svg";
import EditLogo from "../../../../styles/svg/edit-client.svg";
import { formatPhoneNumber } from "../../../../utils/helpers";
import LongFormDialog from "../../../../components/Dialog/LongFormDialog";
import EditClient from "../../components/EditClient";
import { useMediaQuery } from "react-responsive";

function ClientDetails() {
  const params = useParams();
  const isMobile = useMediaQuery({ maxWidth: "699px" });
  const redirect = useNavigate();
  const { showBoundary } = useErrorBoundary();
  const { responseData, isLoading, isError, error } = useClientDetail(
    params.clientId
  );

  useEffect(() => {
    if (error) {
      showBoundary(error);
    }
  }, [error]);

  const role = JSON.parse(localStorage.getItem("userData")).role;
  const [anchorEl, setAnchorEl] = useState(false);

  const handleClose = () => {
    setAnchorEl(false);
  };
  const handleClick = () => {
    setAnchorEl(true);
  };

  const editData = {
    user: {
      email: responseData?.email,
      name: responseData?.name,
      position: responseData?.position,
      phone_number: responseData?.phone_number,
    },
    company_name: responseData?.company_name,
    status: responseData?.status,
    client_id: params.clientId,
    zip_code: responseData?.zip_code,
    state: responseData?.state,
    city: responseData?.city,
    address: responseData?.address,
    street_address: responseData?.street_address,
    xrf_single_unit_testing_rate:
      responseData?.xrf_single_unit_testing_rate || null,
    xrf_volume_discount_rate: responseData?.xrf_volume_discount_rate || null,
  };
  return (
    <div>
      {anchorEl && (
        <LongFormDialog
          open={anchorEl}
          handleClose={handleClose}
          title={"Update Client"}
          btnTitle={"Confirm"}
        >
          <EditClient onClose={handleClose} data={editData} />
        </LongFormDialog>
      )}
      {isLoading && (
        <div className="client-detail-loader">
          <Loader color={"black"} />
        </div>
      )}
      {!isLoading && isError && (
        <div className="client-detail-loader">
          <p>{error?.response?.data?.message || "Error"}</p>
        </div>
      )}
      {!isLoading && !isError && (
        <div className="detail-container">
          <div className="detail-action d-flex">
            <p
              className={"status-chip mr-1"}
              style={{ backgroundColor: "#808080", height: "28px" }}
            >
              {responseData?.status}
            </p>
            {role == "admin" && (
              <img
                src={EditLogo}
                onClick={handleClick}
                className="cursor-pointer"
              />
            )}
          </div>

          <Box className="box-container p-24">
            <Box>
              <img src={ClientLogo} />
            </Box>

            <Divider orientation="vertical" flexItem />

            <Box className={isMobile ? "mt-2" : ""}>
              {responseData?.address ? (
                <>
                  <p className="heading-title-name">
                    {responseData?.company_name}
                  </p>
                  <p className="title-details">
                    {`${responseData?.street_address}, ${responseData?.address}`}
                  </p>
                  <p className="title-details">{`${responseData?.city || ""},${
                    responseData?.state || ""
                  } ${responseData?.zip_code || ""}`}</p>
                </>
              ) : (
                <>
                  <p className="heading-title-name">
                    {responseData?.company_name}
                  </p>
                  <p className="title-details">Address: NA</p>
                </>
              )}
            </Box>

            <Divider orientation="vertical" flexItem />

            <Box className={isMobile ? "mt-2" : ""}>
              <p className="heading-title-name">Pricing:</p>
              <div className="d-flex align-items-center">
                <p className="title-details mr-2">
                  XRF Single Unit Testing Rate:
                </p>
                <p className="title-details">
                  {responseData?.xrf_single_unit_testing_rate || "NA"}
                </p>
              </div>
              <div className="d-flex align-items-center">
                <p className="title-details mr-2">XRF Volume Discount Rate:</p>
                <p className="title-details">
                  {" "}
                  {responseData?.xrf_volume_discount_rate || "NA"}
                </p>
              </div>
            </Box>

            <Divider orientation="vertical" flexItem />

            <Box className={isMobile ? "mt-2" : ""}>
              <p className="heading-title-name">Contact Person:</p>
              <p className="title-details">{responseData?.name}</p>
              <p className="title-details">
                {formatPhoneNumber(responseData?.phone_number)}
              </p>
              <p className="title-details">{responseData?.email}</p>
            </Box>

            {/* Add more components or styling as needed */}
          </Box>
        </div>
      )}
    </div>
  );
}

export default ClientDetails;
