import React, { useState } from "react";
import PrimaryButton from "../../../../components/Button/Button";
import LongFormDialog from "../../../../components/Dialog/LongFormDialog";
import EnterSurveyForm from "./EnterSurveyForm";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";

function EnterSurveyResponse() {
  const [anchorEl, setAnchorEl] = useState(false);
      const [refetch, setRefetch] = useCustomSearchParams("refetch");

  const handleClose = () => {
    setAnchorEl(false);
     setRefetch("refetch", refetch ? refetch + 1 : 1);
  };
  const handleClick = () => {
    setAnchorEl(true);
  };
  return (
    <div>
      {anchorEl && (
        <LongFormDialog
          open={anchorEl}
          handleClose={handleClose}
          title={"Survey Response"}
          btnTitle={"Confirm"}
        >
          <EnterSurveyForm
            onClose={handleClose}
            initialData={{ dates: new Date() }}
          />
        </LongFormDialog>
      )}
      <PrimaryButton
        variant="contained"
        className={`capitalize green-clr`}
        onClick={handleClick}
      >
        Enter Survey Response
      </PrimaryButton>
    </div>
  );
}

export default EnterSurveyResponse;
