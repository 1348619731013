import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader,
} from "@mui/material";
import SearchList from "../../../components/Search/SearchList";

const CustomSelectDropdown = ({
  options,
  label,
  onSelectChange,
  defaultValue,
  selectedValue,
  disabled = false,
  isSearch = false,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const handleChange = (event) => {
    const value = event.target.value;
    onSelectChange(value);
  };

  return (
    <div className="assign-inspector-drop-down">
      <FormControl fullWidth className="filter-select">
        <Select
          sx={{
            height: "35px",
            minWidth: "192px",
            alignItems: "center",
            display: "flex",
          }}
          value={selectedValue}
          onChange={handleChange}
          //   defaultValue={selectedValue || ""}
          displayEmpty
          disabled={disabled}
        >
          <MenuItem disabled value="">
            <em
              className="header-action-button-text"
              style={{ fontStyle: "normal", fontSize: "14px" }}
            >
              {label}
            </em>
          </MenuItem>
          {isSearch && (
            <ListSubheader>
              <SearchList
                onSearch={(val) => setSearchQuery(val)}
                searchQuery={searchQuery}
              />
            </ListSubheader>
          )}
          {options
            ?.filter((item) =>
              `${item.label}`.toLowerCase().includes(searchQuery.toLowerCase())
            )
            .map((option, index) => (
              <MenuItem
                key={index}
                value={option.value}
                className="dropdown-menu-options"
              >
                <p className="dropdown-options">{option.label}</p>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default CustomSelectDropdown;
