import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRouteError } from "react-router-dom";
import PrimaryButton from "./Button/Button";

function ErrorFallback(props) {
  const redirect = useNavigate();
  const error = useRouteError();
  const [timeLeft, setTimeLeft] = useState(10);

  useEffect(() => {
    // Exit early if the time is already 0
    if (timeLeft === 0) {
      redirect("/");
    }

    // Set up a timer that counts down every second
    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    // Cleanup the timer when the component unmounts or when timeLeft updates
    return () => clearInterval(timerId);
  }, [timeLeft]);

  return (
    <div className="loader-center">
      <div>
        <h1 style={{ color: "red" }}>{error?.response?.data?.message}</h1>

        <div>
          <p>
            You will be automatically redirected to your homepage in 10 seconds.
            If you prefer to return now, please click the button below....
            {timeLeft}
          </p>
          <PrimaryButton onClick={() => redirect("/")}>
            Go to Home Page
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}

export default ErrorFallback;
