import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { Box, Divider } from "@mui/material";
import { useClientDetail } from "../../../../../reactQuery/client";
import Loader from "../../../../../components/Loader";
import ClientLogo from "../../../../../styles/svg/client-logo.svg";
import { useBuildingsById } from "../../../../../reactQuery/building";
import { formatPhoneNumber } from "../../../../../utils/helpers";
import { useErrorBoundary } from "react-error-boundary";

function UnitDetail({ detail, detailLoading }) {
  const params = useParams();
  const isMobile = useMediaQuery({ maxWidth: "830px" });
  const { showBoundary } = useErrorBoundary();
  const { responseData, isLoading, isError, error } = useClientDetail(
    params.clientId
  );

  useEffect(() => {
    if (error) {
      showBoundary(error);
    }
  }, [error]);

  return (
    <div>
      {(isLoading || detailLoading) && (
        <div className="client-detail-loader">
          <Loader color={"black"} />
        </div>
      )}
      {!isLoading && isError && (
        <div className="client-detail-loader">
          <p>{error?.response?.data?.message || "Error"}</p>
        </div>
      )}
      {!isLoading && !detailLoading && !isError && (
        <div className="detail-container">
          <Box className="box-container p-24">
            <Box>
              <img src={ClientLogo} />
            </Box>

            <Divider orientation="vertical" flexItem />

            <Box className={isMobile ? "mt-2" : ""}>
              {responseData?.address ? (
                <>
                  <p className="heading-title-name">
                    {responseData?.company_name}
                  </p>
                  <p className="title-details">
                    {`${responseData?.street_address}, ${responseData?.address}`}
                  </p>
                  <p className="title-details">{`${responseData?.city || ""},${
                    responseData?.state || ""
                  } ${responseData?.zip_code || ""}`}</p>
                </>
              ) : (
                <>
                  <p className="heading-title-name">
                    {responseData?.company_name}
                  </p>
                  <p className="title-details">Address: NA</p>
                </>
              )}
            </Box>

            <Divider orientation="vertical" flexItem />

            <Box className={isMobile ? "mt-2" : ""}>
              <div className="d-flex align-items-center">
                <p className="heading-title-name mr-2">Super Name:</p>
                <p className="title-details">{detail?.superintendent_name}</p>
              </div>
              <div className="d-flex align-items-center">
                <p className="heading-title-name mr-2">Super Phone:</p>
                <p className="title-details">
                  {formatPhoneNumber(detail?.superintendent_number)}
                </p>
              </div>
              {/* <div>
                <p className="heading-title-name">Super Apt:</p>
                <p className="title-details">{detail?.superintendent_number}</p>
              </div> */}

              {/* <p className="title-details">
                {formatPhoneNumber(responseData?.phone_number)}
              </p> */}
            </Box>
            <Divider orientation="vertical" flexItem />

            <Box className={isMobile ? "mt-2" : ""}>
              <div className="d-flex align-items-center">
                <p className="heading-title-name mr-2">Block:</p>
                <p className="title-details">{detail?.block}</p>
              </div>
              <div className="d-flex align-items-center">
                <p className="heading-title-name mr-2">Lot:</p>
                <p className="title-details">{detail?.lot}</p>
              </div>
              <div className="d-flex align-items-center">
                <p className="heading-title-name mr-2">MDR:</p>
                <p className="title-details">{detail?.mdr}</p>
              </div>
            </Box>
          </Box>
        </div>
      )}
    </div>
  );
}

export default UnitDetail;
