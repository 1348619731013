import React from "react";
import LeftFilters from "./LeftFilters";
import RightFilters from "./RightFilters";
import DownloadAuditReport from "../../../Client/detail/components/Units/DownloadAuditReport";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";

function Filters() {
   const [building_id] = useCustomSearchParams("building");
   const [client] = useCustomSearchParams("client");
  return (
    <div className="tickets-filter d-flex align-items-center justify-content-space-between">
      <LeftFilters />
      {/* <RightFilters /> */}
      <DownloadAuditReport building={building_id} client ={client}/>
    </div>
  );
}

export default Filters;
