import React, { useState } from "react";
import { formatPhoneNumber } from "../../utils/helpers";
import InspectorForm from "../../pages/InspectorModule/components/InspectorForm";

function UpdateUser({ onClose, data }) {

  const [formData, setFormData] = useState({
    email: data?.email || "",
    name: data?.name || "",
    phone_number: formatPhoneNumber(data?.phone_number) || "",
    certificate_id: data?.inspector_detail.certificate_id || "",
    certification_expire_date:
      data?.inspector_detail.certificate_expire_date || "",
    // has_permission: data?.has_permission || "",
    xrf_devices: data?.inspector_detail.xrf_devices || "",
    id: data?.inspector_detail.inspector_id || "",
    file_name: data?.inspector_detail.file_name || "",
  });
  return (
    <div>
      <InspectorForm
        initialValues={formData}
        onClose={onClose}
        isEdit={true}
        isInspector={true}
      />
    </div>
  );
}

export default UpdateUser;
