import moment from "moment";

export const columns = [
  {
    field: "building_address",
    headerName: "Building",
    renderCell: (params) => {
      return <p className="">{params.value}</p>;
    },
    minWidth: 110,
    flex: 1.2,
    // editable: true,
  },
  {
    field: "scheduled_date",
    headerName: "Date",
    renderCell: (params) => {
      return <p>{moment(params.value).format("MM/DD/YYYY")}</p>;
    },
    minWidth: 130,
    flex: 1,
    // editable: true,
    sortable: false,
  },
  {
    field: "apartment",
    headerName: "Unit",
    renderCell: (params) => {
      return <p className="unit-ticket">{params.value}</p>;
    },
    minWidth: 100,
    flex: 1,
    // editable: true,
    sortable: false,
  },

  {
    field: "service_type",
    headerName: "Type",
    renderCell: (params) => {
      return <p>{params.value}</p>;
    },
    minWidth: 130,
    flex: 1,
    // editable: true,
    sortable: false,
  },
];
