import { configureStore } from "@reduxjs/toolkit";
import TicketSlice from "./redux/ticketSlice";
import ticketAssignSlice from "./redux/ticketAssignSlice";
import complianceSlice from "./redux/complianceSlice";

const store = configureStore({
  reducer: {
    ticket: TicketSlice,
    assignTicket: ticketAssignSlice,
    compliance: complianceSlice,
  },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(conditionMiddleware),
});

export default store;
