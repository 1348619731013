import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Button, FormHelperText } from "@mui/material";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";


const SignatureForm = ({
  setValue,
  clearErrors,
  setError,
  register,
  errors,
}) => {
  const sigCanvas = useRef({});
  const [imageURL, setImageURL] = useState(null);

  const dataURLToBlob = (dataURL) => {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };
  // Save signature as image
  const saveSignature = () => {
    if (!sigCanvas.current.isEmpty()) {
      const dataURL = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");

      const imageBlob = dataURLToBlob(dataURL); // Convert base64 to Blob
      const imageFile = new File([imageBlob], "signature.png", {
        type: "image/png",
      });
      setImageURL(dataURL); // this will store the image URL to state

      setValue("signature_attachment", imageFile);
    }
  };

  // Restore signature when resizing or re-rendering
  const restoreSignature = () => {
    if (imageURL) {
      const img = new Image();
      img.src = imageURL;
      img.onload = () => {
        sigCanvas.current.fromDataURL(imageURL); // Restore signature from base64
      };
    }
  };

  // Handle window resize and restore signature
  useEffect(() => {
    const handleResize = () => {
      restoreSignature(); // Restore signature after resizing
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [imageURL]);

  // Clear signature
  const clearSignature = () => {
    sigCanvas.current.clear();
    setImageURL("");
    setValue("signature_attachment", "");
  };

  return (
    <div
      style={{
        textAlign: "center",
        marginTop: "24px",
        justifyContent: "center",
        // display: "flex",
      }}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
        }}
      >
        <input
          style={{ display: "none" }}
          {...register("signature_attachment", {
            required: `Signature Attachment is required`,
          })}
        />
        <SignatureCanvas
          ref={sigCanvas}
          penColor="black"
          canvasProps={{ className: "sigCanvas" }}
          backgroundColor="#fff"
          onEnd={saveSignature}
        />
        <p
          style={{
            position: "absolute",
            right: "5px",
            bottom: "11px",
            display: "flex",
          }}
        >
          <p
            style={{
              fontSize: "14px",
              color: "#16304F",
              marginRight: "4px",
            }}
          >
            Signature
          </p>
          <p
            style={{
              fontSize: "13px",
              color: "#919EAB",
            }}
          >
            (Firma)
          </p>
        </p>
        {imageURL && (
          <IconButton
            onClick={clearSignature}
            aria-label="clear signature"
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
            }}
          >
            <ClearIcon />
          </IconButton>
        )}
      </div>
      <FormHelperText sx={{ color: "#d32f2f" }}>
        {errors?.["signature_attachment"] &&
          errors?.["signature_attachment"].message}
      </FormHelperText>
      {/* <div style={{ marginTop: "10px" }}>
        <Button variant="contained" onClick={saveSignature}>
          Save Signature
        </Button>
        <Button
          variant="outlined"
          onClick={clearSignature}
          style={{ marginLeft: "10px" }}
        >
          Clear
        </Button>
      </div> */}
      {/* {imageURL ? (
        <div style={{ marginTop: "20px" }}>
          <h4>Your signature:</h4>
          <img
            src={imageURL}
            alt="Signature"
            style={{ border: "1px solid black", width: "100%" }}
          />
        </div>
      ) : null} */}
    </div>
  );
};

export default SignatureForm;
