import React from "react";
import { useParams } from "react-router-dom";
import { useBuildingsById } from "../../../../../reactQuery/building";
import Heading from "./Heading";
import Loader from "../../../../../components/Loader";
import GlobalSearch from "../../../../../components/Search";
import Table from "./Table";
import { useCustomSearchParams } from "../../../../../hooks/useCustomSearchParams";
import RightFilters from "./RightFilters";

function UnitTickets() {
  const param = useParams();
     const [year, setYear] = useCustomSearchParams("year");
  const { detail, isLoading, responseData, totalRecords, isError, error } =
    useBuildingsById({
      id: param.buildingId,
      year: year || new Date().getFullYear(),
    });

  return (
    <>
      {isLoading && (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      )}
      {!isLoading && isError && (
        <div className="loader-center">
          <p>{error?.response?.data?.message || "Error"}</p>
        </div>
      )}
      {!isLoading && !isError && (
        <div>
          <Heading
            detail={responseData?.filter((el) => el.unit_id == param.unitId)}
            address={detail?.address}
            param={param}
          />
          <div className="tickets-filter d-flex justify-content-space-between mt-4">
            <GlobalSearch />
            <RightFilters />
          </div>
          <Table />
        </div>
      )}
    </>
  );
}

export default UnitTickets;
