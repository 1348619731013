import moment from "moment";
import FlexibleIcon from "../../../styles/svg/flexible-icon.svg";
import reportIcon from "../../../styles/svg/report-icon.svg";
import downloadIcon from "../../../styles/svg/download-icon.svg";
import AssignTicket from "./AssignTicket";
// import EditIcon from "./EditIcon";
// import ReportUpload from "./ReportUpload";

export const columns = [
  {
    field: "job_id",
    headerName: "Ticket Id",
    renderCell: (params) => {
      return <p>{params.value}</p>;
    },
    minWidth: 120,
    flex: 0.8,
    // editable: true,
    sortable: true,
  },
  {
    field: "scheduled_date",
    headerName: "Date",
    renderCell: (params) => {
      return <p>{moment(params.value).local().format("MM/DD/YYYY")}</p>;
    },
    minWidth: 130,
    flex: 1,
    // editable: true,
    sortable: false,
  },
  {
    field: "client_name",
    headerName: "Client",
    renderCell: (params) => {
      return <p className="underline">{params.value}</p>;
    },
    minWidth: 100,
    flex: 1,
    // editable: true,
    sortable: false,
  },
  {
    field: "building_address",
    headerName: "Building",
    renderCell: (params) => {
      return <p className="">{params.value}</p>;
    },
    minWidth: 110,
    flex: 1.5,
    // editable: true,
  },
  {
    field: "apartment",
    headerName: "Apt#",
    renderCell: (params) => {
      return <p className="">{params.value}</p>;
    },
    minWidth: 80,
    flex: 0.5,
    // editable: true,
    sortable: false,
  },
  {
    field: "unit_size",
    headerName: "Unit Size",
    renderCell: (params) => {
      return <p>{params.value}</p>;
    },
    minWidth: 100,
    flex: 0.7,
    // editable: true,
    sortable: false,
  },
  {
    field: "unit_status",
    headerName: "Unit Status",
    renderCell: (params) => {
      return (
        <p
          className={`status-chip ${
            params.value == "Vacant" ? "bg-orangr-status" : "bg-blue-status"
          } `}
        >
          {params.value}
        </p>
      );
    },
    minWidth: 100,
    flex: 0.9,
    // editable: true,
    sortable: false,
  },
  {
    field: "service_type",
    headerName: "Service Type",
    renderCell: (params) => {
      return (
        <p
          className="status-chip"
          style={{ backgroundColor: params.row.service_icon || "#11b50c" }}
        >
          {params.value}
        </p>
      );
    },
    minWidth: 198,
    flex: 1.2,
    // editable: true,
    sortable: false,
  },
  {
    field: "inspector_name",
    headerName: "Inspector",
    renderCell: (params) => {
      return <p className="">{params.value}</p>;
    },
    minWidth: 100,
    flex: 1,
    // editable: true,
    sortable: false,
  },
  {
    field: "action",
    headerName: "Action",
    renderHeader: (cell) => (
      <div className="w-100 d-flex justify-content-center">
        <p>Action</p>
      </div>
    ),
    renderCell: (params) => {
      return <AssignTicket params={params} />;
    },
    minWidth: 70,
    flex: 0.7,
    // editable: true,
    sortable: false,
    headerClassName: "center-header",
  },
];
