import React, { useEffect } from "react";
import CustomSelectDropdown from "../../../../components/CustomSelectDropDown";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";

function ComplianceYear({name}) {
  const [year, setYear] = useCustomSearchParams("year");
  const currentYear = new Date().getFullYear();
  const lastFiveYears = [{ label: currentYear + 1, value: currentYear + 1 }];

  for (let i = 0; i < 4; i++) {
    lastFiveYears.push({ label: currentYear - i, value: currentYear - i });
  }
  useEffect(() => {
    setYear("year", year || currentYear);
  }, []);

  return (
    <div className="d-flex align-items-center mr-2">
      <p className="compliance-year-txt mr-2">{name}</p>
      <div className="">
        <CustomSelectDropdown
          defaultValue={year || currentYear}
          options={lastFiveYears}
          label={""}
          onSelectChange={(value) => {
            setYear("year", value);
          }}
        />
      </div>
    </div>
  );
}

export default ComplianceYear;
