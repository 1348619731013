import React, { useState } from "react";
import DeleteIcon from "../../../styles/svg/delete-icon.svg";
import useCustomMutate from "../../../hooks/useMutate";
import { deleteJobs } from "../../../sevices/jobApi";
import ConfirmationDialog from "../../../components/Dialog/ConfirmationDialog";

function DeleteTicket({ params }) {
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState(false);
  const { mutate } = useCustomMutate(deleteJobs, ["jobs"], () => {
    setLoading(false);
    setDialog(false);
  });
  const Delete = () => {
    setLoading(true);
    mutate(params.row.job_id);
  };
  return (
    <div>
      {dialog && (
        <ConfirmationDialog
          open={dialog}
          handleClose={() => setDialog(false)}
          message={"Are you sure you want to delete this ticket?"}
          callback={Delete}
          loading={loading}
          btnTitle={"Delete"}
          title={"Delete Ticket"}
          btnColor="var(--dark-light-error, #B71D18)"
        />
      )}
      <img
        src={DeleteIcon}
        onClick={() => setDialog(true)}
        className="mr-1 cursor-pointer "
      />
    </div>
  );
}

export default DeleteTicket;
