import moment from "moment";
// import RedFlag from "../../../../../styles/svg/red-unit.svg";
// import GreenFlag from "../../../../../styles/svg/green-unit.svg";
import RedFlag from "../../../../../styles/svg/red-unit.svg";
import GreenFlag from "../../../../../styles/svg/green-unit.svg";
import frictionRed from "../../../../../styles/svg/red-friction.svg";
import frictionGreen from "../../../../../styles/svg/green-friction.svg";
import ReportDownload from "../../../../Tickets/components/ReportDownload";
import ViewDocument from "../RecentUploads/ViewDocument";
import { servicesVariables } from "../../../../../utils/constants";

export const columns = [
  {
    field: "service_type",
    headerName: "Service Type",
    renderCell: (params) => {
      return <p>{params.value}</p>;
    },
    minWidth: 130,
    flex: 0.8,
    // editable: true,
    sortable: false,
  },
  {
    field: "report_status",
    headerName: "Result",
    renderHeader: (cell) => (
      <div className="w-100 d-flex justify-content-center">
        <p className="size-14 text-color weight-500">Result</p>
      </div>
    ),
    renderCell: (params) => {
      return (
        <div className="w-100 d-flex justify-content-center">
          <p>
            {/* {params.value == "Negative" ? (
              <img src={GreenFlag} />
            ) : params.value == "Positive" ? (
              <img src={RedFlag} />
            ) : (
              ""
            )} */}
            {params?.row?.service_id ? (
              params?.row?.service_id ==
                servicesVariables.xrfEnitrUnitNegative &&
              params?.value == "Negative" ? (
                <img src={GreenFlag} />
              ) : params?.row?.service_id ==
                  servicesVariables.xrfEnitrUnitPositive &&
                params?.value == "Positive" ? (
                <img src={RedFlag} />
              ) : params?.row?.service_id ==
                  servicesVariables.xrfFrictionPositive &&
                params?.value == "Positive" ? (
                <img src={frictionRed} />
              ) : params?.row?.service_id ==
                  servicesVariables.xrfFrictionNegative &&
                params?.value == "Negative" ? (
                <img src={frictionGreen} />
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </p>
        </div>
      );
    },
    minWidth: 130,
    flex: 1.2,
    // editable: true,
    sortable: false,
    headerClassName: "center-header",
  },
  {
    field: "scheduled_date",
    headerName: "Date",
    renderCell: (params) => {
      return <p>{moment(params.value).format("MM/DD/YYYY")}</p>;
    },
    minWidth: 130,
    flex: 1,
    // editable: true,
    sortable: false,
  },

  {
    field: "action",
    headerName: "Action",
    renderCell: (params) => {
      return (
        <div className="d-flex">
          {/* <ReportDownload params={params} idClient={true} /> */}
          {params.row.report_available ? <ViewDocument params={params} /> : ""}
        </div>
      );
    },
    minWidth: 100,
    flex: 0.7,
    // editable: true,
    sortable: false,
  },
];
